import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import TableCarnes from "./table"

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  CardActions,
  TableHead,
  TableContainer,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  Divider,
  Tooltip
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../estados-brasileiros'

import { useNavigate } from "react-router-dom";
import { Table } from "react-feather";
import ConvertToSignature from "./convert-to-signature";


function AddNewClient(props) {
  let { getApi,postApi,deleteApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();

  const { onClose, selectedValue, open } = props;


  
const [invoiceGroup, setInvoiceGroup] = useState({});
const [invoices, setInvoices] = useState([]);

const [loading, setLoading] = useState(true);
const [blockAction, setBlockAction] = useState(false);
const [cancelling, setCancelling] = useState(false);
const [loadingArchiving, setLoadingArchiving] = useState(false);


const [openConvertSignature, setOpenConvertSignature] = useState(false);


  useEffect(() => {
    if(selectedValue.id){

      LoadInvoiceGroup();
      setInvoiceGroup(selectedValue)
    }
  },[selectedValue]);

  const handleClose = () => {
    onClose(selectedValue);

  };
  const [noRowsMessage, setNoRowsMessage] = React.useState();

  const LoadInvoiceGroup = async (page_arg=null, limit_arg=null) => {
    

    setInvoiceGroup([]);
    setLoading(true);
    let response = await getApi("/invoice/carne/"+selectedValue.id, {});
    if(response.message){
      if(response.message=='This action is unauthorized.'){
        setNoRowsMessage("Acesso não autorizado");
        setLoading(false);
  
        return ;
      }
    }
    
    setInvoiceGroup(response.carne);
    setInvoices(response.carne.invoices);
    setLoading(false);
  
  };

  const CancelAllInvoices = async (page_arg=null, limit_arg=null) => {

    if (!window.confirm("Você realmente deseja fazer isso?")) {
      return ;
    }

    setBlockAction(true);
    setCancelling(true);
    let response = await deleteApi("/invoice/carne/"+selectedValue.id, {});

    if(response.message){
      if(response.message=='This action is unauthorized.'){
        alert("Acesso não autorizado");
       
    setBlockAction(false);
    setCancelling(false)
  
        return ;
      }
    }

    if(response.success){

      setInvoiceGroup([]);
      LoadInvoiceGroup();
    }else if(response.message){
      if(response.message=='This action is unauthorized.'){
        alert("Você não tem autorização");
        setBlockAction(false);
    setCancelling(false)
  

        return ;
      }
    }else{
      alert("Um erro ocorreu, por favor, tente novamente");
    }
    setBlockAction(false);
    setCancelling(false)
  
  };
  const ArchiveAct = async (archiveSt) => {
    if (archiveSt==true && !window.confirm("Este carnê estará classificada como arquivada.\nVocê deseja continuar?")) {
      return ;
    }
    
    setLoadingArchiving(true);
    let response = await postApi("/invoice/carne/archive/"+selectedValue.id+"/"+archiveSt,{});
    if(response.message){
      if(response.message=='This action is unauthorized.'){
        alert("Acesso não autorizado");
        setLoadingArchiving(false);
  
        return ;
      }
    }

    if(response.error){
      alert(response.error.toString());
    }else{

      alert("Carnê "+(archiveSt?"arquivada":"desarquivada"))
      setInvoiceGroup([]);
      LoadInvoiceGroup();
      
    }
    setLoadingArchiving(false)
  };

  

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitle id="simple-dialog-title" ><center>Informações do Carnê - #{invoiceGroup?invoiceGroup.id:null}</center></DialogTitle>
       
            <Grid container justifyContent="center" >
            <Grid item>
            <Typography color="#df4759" variant="h7" ></Typography>
            {loading &&
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>}
            </Grid>
          </Grid> 


      {loading==false && 
      <React.Fragment>
      <Grid container spacing={0} marginLeft={2} marginRight={2}>
        <Grid item xs={window.isNativeApp?12:4}>
          <Card >
            <CardContent>
              <Typography sx={{ fontSize: 18 }} variant="subtitle1" color="text.secondary" gutterBottom>
                Dados do Carnê
              </Typography>

              <Typography variant="body2">
                Referência: {invoiceGroup.reference}
              </Typography>
              
              <Typography variant="body2">
                Parcelas Totais:  {invoiceGroup.installments}
              </Typography>
              <Typography variant="body2">
                Parcelas Pagas:  {invoiceGroup.installments_paid}
              </Typography>
              <Typography variant="body2">
                Parcelas Pendentes:  {invoiceGroup.installments_pending}
              </Typography>
              <Typography variant="body2">
                Parcelas Vencidas:  {invoiceGroup.installments_overdue}
              </Typography>
              <Typography variant="body2">
                Parcelas Expiradas:  {invoiceGroup.installments_expired}
              </Typography>
            </CardContent>
          </Card>

        </Grid>
        <Grid item xs={window.isNativeApp?12:4} >
        <Card >
            <CardContent>
              <Typography sx={{ fontSize: 18 }} variant="subtitle1" color="text.secondary" gutterBottom>
                Dados do Cliente
              </Typography>

              
              <Typography variant="body2">
                Nome:  <Link
                        href={"/meus-clientes/visualizar/"+invoiceGroup.costumer.id}>
                        {invoiceGroup.costumer.fullname}
                      </Link> 
              </Typography>
              <Typography variant="body2">
                CPF/CNPJ:  {invoiceGroup.costumer.tax_identifier}
              </Typography>
              <Typography variant="body2">
                Referência:  {invoiceGroup.costumer.reference}
              </Typography>
              <Typography variant="body2">
                E-mail:  {invoiceGroup.costumer.email}
              </Typography>
              <Typography variant="body2">
                Telefone: {invoiceGroup.costumer.phone}
              </Typography>
             
            </CardContent>
          </Card>

        </Grid>
        <Grid item xs={window.isNativeApp?12:4}>
          <Card >
            <CardContent>
              <Typography sx={{ fontSize: 18 }} variant="subtitle1" color="text.secondary" gutterBottom>
                Dados do Carnê
              </Typography>

             
                <Link  href={invoiceGroup.cover_link} target="_blank">
                  <Button variant="outlined" size="small" color={'boot_secondary'}>  Ver Capa</Button>
                </Link>

                <Link  href={invoiceGroup.url_slip} target="_blank">
                  <Button variant="contained" size="small" color={'boot_warning'}>  Ver Carnê</Button>
                </Link>

                <Button variant="contained" size="small" color={'boot_danger'} onClick={CancelAllInvoices} disabled={blockAction}> Cancelar</Button>
                
                {window.isNativeApp && <center>
                  
                  <Tooltip title="Arquive os carnês antigas que não precisam ser exibidas.">
                  <Button
                      variant="contained"
                      size="small"
                      sx={{marginTop:3}}
                      color={"boot_light"}
                      onClick={e=> ArchiveAct(!invoiceGroup.archived)}
                      disabled={loadingArchiving||invoiceGroup.installments_pending>0}>
                      {invoiceGroup.archived?"Desarquivar Carnê":"Arquivar Carnê"}
                  </Button>
                </Tooltip>
                </center>
                  }
                
                 
                {!window.isNativeApp && 
                  
                  <Tooltip title="Arquive os carnês antigas que não precisam ser exibidas.">
                  <Button
                      variant="contained"
                      size="small"
                      color={"boot_light"}
                      onClick={e=> ArchiveAct(!invoiceGroup.archived)}
                      disabled={loadingArchiving||invoiceGroup.installments_pending>0}>
                      {invoiceGroup.archived?"Desarquivar":"Arquivar"}
                  </Button>
                </Tooltip>
                }
                
                <p></p>
                {
                  <Tooltip title="Converter o carnê em assinatura e gere as próximas faturas automáticamente.">
                  
                    <Button
                        variant="contained"
                        size="small"
                        color={"boot_info"}
                        onClick={e=> setOpenConvertSignature(true)}
                        disabled={loadingArchiving||invoiceGroup.convert_signature}>
                        {invoiceGroup.convert_signature?"Convertido em assinatura":"Converter em assinatura"}
                    </Button>
                  </Tooltip>
                }
          
              
                


                <Grid container justifyContent="center" >
            <Grid item>
            <Typography color="#df4759" variant="h7" ></Typography>
            {cancelling &&
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>}
            </Grid>
          </Grid> 

            </CardContent>
          </Card>

        </Grid>
        
        </Grid>

        

        <Grid container spacing={0} marginLeft={2} marginRight={2}>
          <Grid item xs={12} >
            <TableCarnes blockAction={blockAction} setBlockAction={setBlockAction} invoices={invoices}/>
          </Grid>
          
          <Grid item xs={12} >

          <Box textAlign='right' marginTop={7} marginBottom={5} marginRight={3}>
              <Button variant='contained' color="secondary" onClick={handleClose}>
               Fechar
              </Button>
          </Box>
          </Grid>
       
              
        </Grid>
        </React.Fragment>}
                
        {invoiceGroup &&
          openConvertSignature &&
          <ConvertToSignature invoice_group={invoiceGroup}
            open={openConvertSignature}
            key={invoiceGroup}
            invoices={invoices}
            setOpen={setOpenConvertSignature} />
        }
        



    </Dialog>
  );
}


export default AddNewClient;
