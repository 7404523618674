import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useParams } from 'react-router';
import Quagga from 'quagga'


import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';


const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function ScannerBarCodeBoleto({state}) {
 

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();

let [barCode, setBarCode] = useState('');
let [loading, setLoading] = useState(false);
 
useEffect( () => {
  const initialize = async () => {

  if(barCode.length>=44){
    RetornarLinhaDigitavel(barCode);
  }
  setBarCode(String(barCode).replace(/\D/g, ""))
};

initialize();
}, [barCode]);


const [noRowsMessage, setNoRowsMessage] = React.useState();

const QueryBarCode = async()=>{
  
  setLoading(true);
  let response = await postApi("/invoice/barcode", {
    barcode:barCode
  });

  if(response.message){
    if(response.message=='This action is unauthorized.'){
      alert("Acesso não autorizado");
      setLoading(false);

      return ;
    }
  }

  if(response.success){
    window.location.href="/cobrancas/show/"+response.invoice_id;
  }else{
    alert(String(response.error.toString()));
  }
  
  setLoading(false);
}


  const RetornarLinhaDigitavel= (codigoBarra) =>{
        let codigoBarraFormatado = String(codigoBarra).replace(/\./g, '').replace(/ /g, '')
        if (codigoBarraFormatado.length !== 44) {
          alert("Código inválido");
            return "boleto inválido"
        }
        else {
            if (codigoBarraFormatado.slice(0, 1) !== '8') {
              QueryBarCode();
            }
            else {
              alert("Código inválido");
              setBarCode('');
              //  return LinhaDigitavelConsumo(codigoBarraFormatado)
            }
        }
    }

    const LinhaDigitavelBoleto= (codigoBarra)=> {
      let codigoBanco = codigoBarra.slice(0, 3)
      let codigoMoeda = codigoBarra.slice(3, 4)
      let campoLivreBlocoUm = codigoBarra.slice(19, 24)
      let digitoVerificadorUm = calcularVerificadorMod10(`${codigoBanco}${codigoMoeda}${campoLivreBlocoUm}`)
      let campoLivreBlocoDois = codigoBarra.slice(24, 34)
      let digitoVerificadorDois = calcularVerificadorMod10(campoLivreBlocoDois)
      let campoLivreBlocoTres = codigoBarra.slice(34, 44)
      let digitoVerificadorTres = calcularVerificadorMod10(campoLivreBlocoTres)
      let digitoVerificadorQuatro = codigoBarra.slice(4, 5)
      let fatorVencimento = codigoBarra.slice(5, 9)
      let valor = codigoBarra.slice(9, 19)

      return codigoBanco
          + codigoMoeda
          + campoLivreBlocoUm
          + digitoVerificadorUm + " "
          + campoLivreBlocoDois
          + digitoVerificadorDois + " "
          + campoLivreBlocoTres
          + digitoVerificadorTres + " "
          + digitoVerificadorQuatro + " "
          + fatorVencimento
          + valor
  }

      //Funcão responsavel por calcular os numeros verificadores referente a linha
    //Digitavél do boleto
    const calcularVerificadorMod10= (codigoBarraSessao) =>{
      if (codigoBarraSessao === "") {
          return "";
      }
      let digitoVerificador = 0;
      let numeroPar = true;
      let i = codigoBarraSessao.length
      for (let i = codigoBarraSessao.length; i > 0; i--) {
          const digito = parseInt(codigoBarraSessao[i - 1]);
          if (numeroPar) {
              digitoVerificador += Math.floor((digito * 2) / 10) + ((digito * 2) % 10);
          } else {
              digitoVerificador += digito;
          }
          numeroPar = !numeroPar;
      }
      digitoVerificador = digitoVerificador % 10;
      if (digitoVerificador !== 0) {
          digitoVerificador = 10 - digitoVerificador;
      }
      return String(digitoVerificador);
  }


  return (
    <React.Fragment>
      <Helmet title={"Cobrança"} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
          Procurar por código de barras
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/cobrancas">
              Cobranças
            </Link>
            <Typography>Procurar por código de barras</Typography>
          </Breadcrumbs>
        </Grid>
 
      
      </Grid>


      <Divider my={6} />
    
    
        <Grid sx={{justifyContent:'center', marginTop:5}} container >
             
       
          <Grid item md={6}>
          <center><Typography variant="subtitle2">Identifique uma fatura através do código de barras do boleto</Typography></center>

          <TextField 
          value={barCode}
          disabled={loading}
          onChange={e=> setBarCode(String(e.target.value).replace(/\D/g, ""))}
          label="Informe ou escaneie o código de barras" variant="outlined" fullWidth={true} />
          
          <center><Typography variant="caption">Escaneie ou digite o código e saiba qual fatura se refere.</Typography></center>


        {loading && 
                  <Grid item>
                  <Box sx={{ display: 'flex' }} justifyContent="center">
                    <CircularProgress />
                  </Box>
                </Grid>
                }
          </Grid>



      
      </Grid>
       

    </React.Fragment>
  );
}

export default ScannerBarCodeBoleto;
