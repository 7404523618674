import React from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AlertTitle,
  Button,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Divider,
  CircularProgress,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "../../../assets/styles/datagrid-improved.css"
import { Box } from "@mui/system";


function DataGridImprovedCarneList({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading,  noRowsMessage,
  handleClickOpenEditClient
}) {

  
  return (
    <React.Fragment>

          
{window.isNativeApp && 
loading && <Grid item>
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}

    {!window.isNativeApp &&
        <DesktopTable
        rowsPerPageOptions={rowsPerPageOptions}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        paginationMode={'server'}
        onPageChange={onPageChange }
        loading={loading}
        noRowsMessage={noRowsMessage}
        
      />
      }

    {window.isNativeApp &&
        <MobileTable
        rowsPerPageOptions={rowsPerPageOptions}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        paginationMode={'server'}
        onPageChange={onPageChange }
        loading={loading}
        handleClickOpenEditClient={handleClickOpenEditClient}
        noRowsMessage={noRowsMessage}
      />
      }

    </React.Fragment>

  );
}


function DesktopTable({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading,handleClickOpenEditClient,noRowsMessage
}) {
  return (
    <React.Fragment>
     <DataGrid
            rowsPerPageOptions={rowsPerPageOptions}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            page={page}
            onPageSizeChange={onPageSizeChange }
            paginationMode={'server'}
            onPageChange={onPageChange }
            loading={loading}  
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {noRowsMessage}
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {noRowsMessage}
                </Stack>
              )
            }}
            
          />

    </React.Fragment>

  );
}

function MobileTable({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading,
  handleClickOpenEditPortalClient,handleClickOpenEditClient,deleteUser,noRowsMessage
}) {
  const navigate = useNavigate();
  const ColorButtonTransaction =(status)=>{
    let color="primary";
    if(status==0){
      color="boot_warning"
    }else if(status==1){
      color="boot_success"
    }else if(status==2){
      color="boot_info"
    }else if(status==3){
      color="boot_secondary"
    }else if(status==4){
      color="boot_dark"
    }else if(status==5){
      color="boot_danger"
    }else if(status==6){
      color="boot_dark"
    }else if(status==7){
      color="boot_success"
    }else if(status==8){
      color="boot_success"
    }else if(status==9){
      color="boot_success"
    }else if(status==10){
      color="boot_secondary"
    }
  
    return color;
  }
 

   //  console.log( Object.getOwnPropertyNames(row));
     // console.log(row);
   
  
  return (
    <React.Fragment>

    <section id="no-more-tables">
    
<table>
	<thead>
		<tr>
      {columns.map(el => 
      	<th>{el.headerName}</th>
      )
    }
			
		</tr>
	</thead>
	<tbody>
  {
    rows.map( elc =>
		<tr style={{
      marginTop:15
    
    }}>
 
   
      <td data-title={'ID' }>{elc.id??' - '}</td>
      <td data-title={'Emissão' }>{elc.created_at?new Date(elc.created_at).toLocaleDateString("pt-br"):' - '}</td>
      <td data-title={'Nome Completo' }>{elc.costumer.fullname??" - "}</td>
      <td data-title={'CPF/CNPJ' }>{elc.costumer.tax_identifier??' - '}</td>
      <td data-title={'Referência' }>{elc.reference!=""?elc.reference:' - '}</td>      
      <td data-title={'1º Vencimento' }>{elc.due_date?new Date(elc.due_date).toLocaleDateString("pt-br"):' - '}</td>
      <td data-title={'V. Parcela' }>{parseFloat(elc.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
      <td data-title={'Parcelas' }>{elc.installments>0?elc.installments:' - '}</td>    
      <td data-title={'Pendentes' }>{elc.installments_pending>0?elc.installments_pending:' - '}</td>    
      <td data-title={'Atrasadas' }>{elc.installments_overdue>0?elc.installments_overdue:' - '}</td>    
      <td data-title={'Expiradas' }>{elc.installments_expired>0?elc.installments_expired:' - '}</td>    
      
      <td data-title={'Ação' } style={{marginTop:5}}>
      <React.Fragment>
          <Grid item sx={{marginBottom:2}}>
          <Button
          variant="contained"
          size="small"
          color={"primary"}
          onClick={e=> handleClickOpenEditClient(elc)}>
          Visualizar
        </Button>
          </Grid>
         
        </React.Fragment>
      </td>
        
		</tr>
    )
  }
	</tbody>
</table>

<center>{noRowsMessage}</center>
<Divider my={3} ></Divider>


  <br></br>
  <br></br>
  <div class="pagination">
    
    <a href="#" onClick={e=> onPageChange(page-1)}>&laquo;</a>
    <a href="#">Página {page+1}</a>
    <a href="#" onClick={e=> onPageChange(page+1)}>&raquo;</a>

  </div>
  
</section>

    </React.Fragment>

  );
}


export default DataGridImprovedCarneList;
