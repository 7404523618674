import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import {enUS, ptBR} from 'date-fns/locale'
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
  Visibility,
  CheckBoxOutlined
} from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  ListSubheader,
  ListItemIcon,
  IconButton,
  Grid,
  Tooltip
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';

import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { DatePicker, LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextareaAutosize from '@mui/base/TextareaAutosize';


function CustomFieldComponent(props) {

  const navigate = useNavigate();


  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();
 
  const { custom_field_value } = props;
  const [editable, setEditable] = React.useState(false);
  const [newValue, setNewValue] = React.useState('');
  const [fieldDataType, setFieldDataType] = React.useState();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);


  const createDateAsUTC= (date)=> {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }
  useEffect(() => {
    if(custom_field_value){
      setNewValue(custom_field_value.value);
      let field=custom_field_value.field;
      let datatype=field.datatype;
      setFieldDataType(datatype.id);
    }
  },[custom_field_value]);

    const SaveValue = async () => {

      setSaving(true);
    let response = await postApi("/custom_field/value/create",{ 
      field_value: [1,2].includes(fieldDataType.id)?createDateAsUTC(newValue):newValue,
      field_id:custom_field_value.field.id,
      reference_identifier:custom_field_value.reference_identifier
    });

    if(response.message){
      if(response.message=='This action is unauthorized.'){
        alert("Você não possui permissão para isso");
        
      setSaving(false);
      setEditable(false)
        return;
      }
    }else if(response){
      setSaving(false);
      setEditable(false)
      custom_field_value.value=[1,2].includes(fieldDataType.id)?createDateAsUTC(newValue):newValue;
    }

  };

  const CancelSave = async () => {
    setNewValue(custom_field_value.value);
    setEditable(false);

  }

  return (
    <React.Fragment>
      
      {fieldDataType==1 &&
       <ComponentDateField editable={editable} setEditable={setEditable} custom_field_value={custom_field_value} 
       setNewValue={setNewValue} newValue={newValue} fieldDataType={fieldDataType} SaveValue={SaveValue} saving={saving} CancelSave={CancelSave}/>
      }
       {fieldDataType==2 &&
       <ComponentDateTimeField editable={editable} setEditable={setEditable} custom_field_value={custom_field_value} 
       setNewValue={setNewValue} newValue={newValue} fieldDataType={fieldDataType} SaveValue={SaveValue} saving={saving} CancelSave={CancelSave}/>
      }
      {fieldDataType==3 &&
       <ComponentTextField editable={editable} setEditable={setEditable} custom_field_value={custom_field_value} 
       setNewValue={setNewValue} newValue={newValue} fieldDataType={fieldDataType} SaveValue={SaveValue} saving={saving} CancelSave={CancelSave} />
      }
      {fieldDataType==4 &&
       <ComponentInteger editable={editable} setEditable={setEditable} custom_field_value={custom_field_value} 
       setNewValue={setNewValue} newValue={newValue} fieldDataType={fieldDataType} SaveValue={SaveValue} saving={saving} CancelSave={CancelSave} />
      }
      {fieldDataType==8 &&
       <ComponentTextAreaField editable={editable} setEditable={setEditable} custom_field_value={custom_field_value} 
       setNewValue={setNewValue} newValue={newValue} fieldDataType={fieldDataType} SaveValue={SaveValue} saving={saving} CancelSave={CancelSave} />
      }
      {fieldDataType==9 &&
       <ComponentSelectField editable={editable} setEditable={setEditable} custom_field_value={custom_field_value} 
       setNewValue={setNewValue} newValue={newValue} fieldDataType={fieldDataType} SaveValue={SaveValue} saving={saving} CancelSave={CancelSave} />
      }

      {saving && 
           <Grid item>
           <Box sx={{ display: 'flex' }} justifyContent="center">
             <CircularProgress  />
           </Box>
        </Grid>
        }
     
    </React.Fragment>
  );
}


function ComponentTextField({custom_field_value,setEditable,editable,setNewValue,newValue,SaveValue,saving, CancelSave}){
  return (
    
    <ListItem>
    <Grid sm={12} md={12} >
    <Grid item lg={12} sx={{marginRight:5, width:"100%"}}>
      {custom_field_value.field.name}
      </Grid>
      <Grid item sm={12}>
      <FormControl sx={{ m: 1,  }} variant="outlined" fullWidth>
          <OutlinedInput
            id={"custom-"+custom_field_value.field.name}
            type={"text"}
            onDoubleClick={e=> setEditable(true)}
            disabled={!editable}
            value={newValue}
            onChange={e=> setNewValue(e.target.value)}
            placeholder={custom_field_value.field.short_description}
            endAdornment={
              !editable && <InputAdornment position="end">
              <IconButton onClick={e=> setEditable(true)}
                aria-label="toggle password visibility"
                edge="end" >
             <EditIcon />
              </IconButton>
            </InputAdornment>
            }
            label={custom_field_value.field.name}
            fullWidth
          />
            <Grid item mt={2} >
            {editable && 
            <React.Fragment>
              <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e=> SaveValue()}>
                Salvar
              </Button>
              <Button sx={{marginLeft:5}}
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e => CancelSave()} >
                Cancelar
              </Button>
            </React.Fragment>
            }
            </Grid>
       
        </FormControl>
        </Grid>
      
      
        </Grid>
    </ListItem>
   
  );
} 


function ComponentSelectField({custom_field_value,setEditable,editable,setNewValue,newValue,SaveValue,saving, CancelSave}){
  return (
   
    <ListItem>
   <Grid lg={12} sx={{marginRight:5, width:"100%"}}>

   <Grid item lg={12} sx={{marginRight:5, width:"100%"}}>
      {custom_field_value.field.name}
      </Grid>
      <Grid item lg={12}>
      <FormControl sx={{ m: 1  }} variant="filled" fullWidth>
          <Select fullWidth
          size="small"
                id={"custom-"+custom_field_value.field.name}
                  onClick={e=> setEditable(true)}
                    value={newValue}
                    readOnly={!editable}
                    onChange={e=> setNewValue(e.target.value)}
                  >
                     {  custom_field_value.field.selectable_value.map(el => (

                      <MenuItem value={el.id}>{el.value}</MenuItem>
                      ))}
                  </Select>
            <Grid item mt={2} >
            {editable && 
            <React.Fragment>
              <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e=> SaveValue()}>
                Salvar
              </Button>
              <Button sx={{marginLeft:5}}
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e => CancelSave()} >
                Cancelar
              </Button>
            </React.Fragment>
            }
            </Grid>
       
        </FormControl>
        </Grid>
      
      
       
      </Grid>
    </ListItem>
  
  );
} 


function ComponentTextAreaField({custom_field_value,setEditable,editable,setNewValue,newValue,SaveValue,saving, CancelSave}){
  return (
   
    <ListItem>
   <Grid lg={12} sx={{marginRight:5, width:"100%"}}>

   <Grid item lg={12} sx={{marginRight:5, width:"100%"}}>
      {custom_field_value.field.name}
      </Grid>
      <Grid item lg={12}>
      <FormControl sx={{ m: 1  }} variant="filled" fullWidth>
          <TextareaAutosize
            id={"custom-"+custom_field_value.field.name}
            minRows={3}
            placeholder={custom_field_value.field.short_description}
            onDoubleClick={e=> setEditable(true)}
            onClick={e=> setEditable(true)}
            readOnly={!editable}
            value={newValue}
            onChange={e=> setNewValue(e.target.value)}
            multiline
            fullWidth
            style={{ minWidth: window.isNativeApp?"100%": "100%" }}
          />
            <Grid item mt={2} >
            {editable && 
            <React.Fragment>
              <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e=> SaveValue()}>
                Salvar
              </Button>
              <Button sx={{marginLeft:5}}
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e => CancelSave()} >
                Cancelar
              </Button>
            </React.Fragment>
            }
            </Grid>
       
        </FormControl>
        </Grid>
      
      
       
      </Grid>
    </ListItem>
  
  );
} 

function ComponentDateField({custom_field_value,setEditable,editable,setNewValue,newValue,SaveValue,saving,CancelSave}){
  return (
    <ListItem>
     <Grid sm={12} md={12} >
    <Grid item lg={12} sx={{marginRight:5}}>
       {custom_field_value.field.name}
     
      </Grid>
      <Grid item lg={12}>
      <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
        <LocalizationProvider locale={enUS} dateAdapter={AdapterDateFns}>
          <MobileDatePicker 
            label={custom_field_value.field.name}
            inputFormat="dd/MM/yyyy"
            onChange={e=> setNewValue(e)}
            value={newValue}
            onOpen={e=> setEditable(true)}
            renderInput={(params) => <TextField {...params} 
            onDoubleClick={e=> setEditable(true)}
            disabled={!editable}
          disableCloseOnSelect={false}
          fullWidth
           endAdornment={
              !editable && <InputAdornment position="end">
              <IconButton onClick={e=> setEditable(true)}
                aria-label="toggle password visibility"
                edge="end" >
             <EditIcon />
              </IconButton>
            </InputAdornment>
            }
            />}
           
          />
                  
        </LocalizationProvider>

            <Grid item mt={2} >
            {editable && 
            <React.Fragment>
              <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e=> SaveValue()}>
                Salvar
              </Button>
              <Button sx={{marginLeft:5}}
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e => CancelSave()} >
                Cancelar
              </Button>
            </React.Fragment>
            }
            </Grid>
       
        </FormControl>
      </Grid>
       
      
      
    
      </Grid>   
    </ListItem>
  );
} 

function ComponentDateTimeField({custom_field_value,setEditable,editable,setNewValue,newValue,SaveValue,saving,CancelSave}){
  return (
    <ListItem>
   <Grid sm={12} md={12} >
    <Grid item lg={12} sx={{marginRight:5, width:"100%"}}>
    
      {custom_field_value.field.name}
     
      </Grid>
      <Grid item lg={12}>
        
      <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
        <LocalizationProvider locale={enUS} dateAdapter={AdapterDateFns}>
          <MobileDateTimePicker 
            label={custom_field_value.field.name}
            inputFormat="dd/MM/yyyy H:ii:ss"
            onChange={e=> setNewValue(e)}
            value={newValue}
            onOpen={e=> setEditable(true)}
            renderInput={(params) => <TextField  {...params} 
            onDoubleClick={e=> setEditable(true)}
            disabled={!editable}
          disableCloseOnSelect={false}
          fullWidth={true}
            />}
           
          />
                  
        </LocalizationProvider>

            <Grid item mt={2} >
            {editable && 
            <React.Fragment>
              <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e=> SaveValue()}>
                Salvar
              </Button>
              <Button sx={{marginLeft:5}}
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e => CancelSave()} >
                Cancelar
              </Button>
            </React.Fragment>
            }
            </Grid>
       
        </FormControl>
      </Grid>
       
      
      
    
  </Grid>   
    </ListItem>
  );
} 


function ComponentInteger({custom_field_value,setEditable,editable,setNewValue,newValue,SaveValue,saving, CancelSave}){
  return (
    <ListItem>
  <Grid sm={12} md={12} >
    <Grid item lg={12} sx={{marginRight:5, width:"100%"}}>
        {custom_field_value.field.name}
      </Grid>
      <Grid item sm={12}>
      <FormControl sx={{ m: 1,  }} variant="outlined" fullWidth>
          <OutlinedInput
            id={"custom-"+custom_field_value.field.name}
            type={"text"}
            onDoubleClick={e=> setEditable(true)}
            disabled={!editable}
            value={newValue}
            fullWidth
            onChange={e=> setNewValue(parseInt(e.target.value))}
            placeholder={custom_field_value.field.short_description}
            endAdornment={
              !editable && <InputAdornment position="end">
              <IconButton onClick={e=> setEditable(true)}
                aria-label="toggle password visibility"
                edge="end" >
             <EditIcon />
              </IconButton>
            </InputAdornment>
            }
            label={custom_field_value.field.name}
          />
            <Grid item mt={2} >
            {editable && 
            <React.Fragment>
              <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e=> SaveValue()}>
                Salvar
              </Button>
              <Button sx={{marginLeft:5}}
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={e => CancelSave()} >
                Cancelar
              </Button>
            </React.Fragment>
            }
            </Grid>
       
        </FormControl>
        </Grid>
      
      
       
  </Grid>
    </ListItem>
  );
} 

export default CustomFieldComponent;
