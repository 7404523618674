import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import {ptBR} from 'date-fns/locale'
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  ListSubheader,
  ListItemIcon,
  Tooltip,
  Grid
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';

import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import CustomFieldComponent from "./CustomFieldComponent";


function ListCustomFieldComponent(props) {

  const navigate = useNavigate();


  

  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();
 
  const { onClose, reference_identifier, custom_field_type, open,hideTitle } = props;

  const [customFieldValueList, setCustomFieldValueList] = React.useState([]);

  useEffect(() => {
    if(reference_identifier){
      LoadValues();
    }
  },[reference_identifier]);


  const LoadValues = async () => {
    
    let response = await getApi("/custom_field/values/"+custom_field_type+"/"+reference_identifier,{ });
    if(response.message){
      if(response.message=='This action is unauthorized.'){
        alert("Você não possui permissão para isso");
        return;
      }
    }

    if(response.lista){
      setCustomFieldValueList(response.lista.map(x=> x.custom_field_value))
    }

  };


  return (
    <List
    sx={{ width: '100%', bgcolor: 'background.paper', marginTop:hideTitle?0:5 }}
  >
    {!hideTitle &&
     <Typography variant="subtitle1" gutterBottom display="inline">
     Campos Personalizados
      </Typography>
    }
    
    <Grid sm={12} md={12}>
   
      {customFieldValueList.map(value =>
        <CustomFieldComponent custom_field_value={value} key={value.reference_identifier}/>
      )}
   </Grid>
  </List>
  );
}


export default ListCustomFieldComponent;
