import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { MoreVertical } from "react-feather";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";

const Divider = styled(MuiDivider)(spacing);


function InformacaoCadastral({listDocumentsPending=null, page='documents'}) {

  const { isInitializedBS,businessAccount } = useAuth();
  return (
  <React.Fragment>

    {!businessAccount &&
      <CircularProgress color="secondary" />
    }
    

    {listDocumentsPending!=null &&
    page!='documents' &&
       listDocumentsPending.length>0 &&
      businessAccount.business_account_status_id==1 &&
      isInitializedBS &&
      <React.Fragment>
     
      <Typography variant="h5" gutterBottom>
            Sua conta está pendente.
          </Typography>
      <Grid container justifyContent="left" >
      <Grid item>
        <a href="/minha-conta/documentos">Clique aqui para iniciar a validação</a>
        </Grid>
       </Grid>
      <Divider my={6} />
      <br></br>
      </React.Fragment>
      }

      {listDocumentsPending!=null &&
        page=='documents' &&
       listDocumentsPending.length>0 &&
      businessAccount.business_account_status_id==1 &&
      isInitializedBS &&
      <React.Fragment>
      <Divider my={6} />
      <Typography variant="h5" gutterBottom>
            Sua conta está pendente.
      </Typography>
      <Grid container justifyContent="left" >
        
        <Grid item>
       Para ativar a conta, será preciso enviar os documentos abaixos.<br></br>
       Essas medidas são necessárias para proteger você contra fraudes de identificação.
        </Grid>
       </Grid>
       {  page!='documents' &&  <Divider my={6} />}
      </React.Fragment>
      }
   

      { page=='documents' &&
       listDocumentsPending.length==0 &&
      businessAccount.business_account_status_id==2 &&
      isInitializedBS &&
      <React.Fragment>
      <Divider my={6} />
      <Typography variant="h5" gutterBottom>
            Sua conta está aberta.
      </Typography>
      <Grid container justifyContent="left" >
        
        <Grid item>
       Você já pode começar a movimentar a sua conta. <br></br>
      Para começar: Cadastre um cliente e realize a emissão de uma cobrança
        </Grid>
       </Grid>
       {  page!='documents' &&  <Divider my={6} />}
      </React.Fragment>
      }


      


      {
       listDocumentsPending.length==0 &&
      businessAccount.business_account_status_id==1 &&
      <React.Fragment>
      <Divider my={6} />
      <Typography variant="h5" gutterBottom>
            Sua conta está em análise.
          </Typography>
      <Grid container justifyContent="left" >
     
        
        <Grid item>
        Estamos realizando uma verificação dos dados fornecidas, em breve você receberá um e-mail/sms com mais informações.
        </Grid>
       </Grid>
       {  page!='documents' &&  <Divider my={6} />}
      </React.Fragment>
      }



      {
      businessAccount &&
      businessAccount.business_account_status_id==3 &&
        <React.Fragment>
          <Divider my={6} />
          <Typography variant="h5" gutterBottom>
                Sua conta está temporáriamente suspensa.
              </Typography>
          <Grid container justifyContent="left" >
            
            <Grid item>
            Detectamos algumas informações/movimentações atípico em sua conta. Por favor, aguarde por mais informações e acompanhe seu e-mail.
            </Grid>
          </Grid>
        </React.Fragment>
      }

{
    businessAccount &&
      businessAccount.business_account_status_id==4 &&
        <React.Fragment>
          <Divider my={6} />
          <Typography variant="h5" gutterBottom>
                Sua conta foi bloqueada.
              </Typography>
          <Grid container justifyContent="left" >
            
            <Grid item>
            Detectamos algumas informações/movimentações atípicas em sua conta, após verificação, decidimos bloquear a sua conta.
            </Grid>
          </Grid>
        </React.Fragment>
      }

{
      businessAccount &&
      businessAccount.business_account_status_id==5 &&
        <React.Fragment>
          <Divider my={6} />
          <Typography variant="h5" gutterBottom>
                Sua conta foi bloqueada.
              </Typography>
          <Grid container justifyContent="left" >
            
            <Grid item>
            Por uma determinação judicial, a sua conta foi bloqueada.
            </Grid>
          </Grid>
        </React.Fragment>
      }

{
  businessAccount &&
      businessAccount.business_account_status_id==6 &&
        <React.Fragment>
          <Divider my={6} />
          <Typography variant="h5" gutterBottom>
                Sua conta foi cancelada.
              </Typography>
          <Grid container justifyContent="left" >
            
            <Grid item>
            Atendendo a sua solicitação, a sua conta foi cancelada.
            </Grid>
          </Grid>
        </React.Fragment>
      }

{
  businessAccount &&
      businessAccount.business_account_status_id==7 &&
        <React.Fragment>
          <Divider my={6} />
          <Typography variant="h5" gutterBottom>
                Sua conta foi cancelada.
              </Typography>
          <Grid container justifyContent="left" >
            
            <Grid item>
           Estamos sempre adaptando e melhorando os nossos serviços, mas neste momento não conseguimos te atender.
            </Grid>
          </Grid>
        </React.Fragment>
      }

{
  businessAccount &&
      businessAccount.business_account_status_id==8 &&
        <React.Fragment>
          <Divider my={6} />
          <Typography variant="h5" gutterBottom>
                Sua conta foi cancelada.
              </Typography>
          <Grid container justifyContent="left" >
            
            <Grid item>
           Devido a uma determinação judicial, a sua conta foi cancelada.
            </Grid>
          </Grid>
        </React.Fragment>
      }

{
  businessAccount &&
      businessAccount.business_account_status_id==9 &&
        <React.Fragment>
          <Divider my={6} />
          <Typography variant="h5" gutterBottom>
            Seu cadastro está irregular.
              </Typography>
          <Grid container justifyContent="left" >
            
            <Grid item>
           O seu cadastro está irregular, enviamos um e-mail com mais informações. Se tiver mais dúvidas, por favor, entre em contato.
            </Grid>
          </Grid>
        </React.Fragment>
      }


  </React.Fragment>
      
      
  )
};

export default InformacaoCadastral;
