import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  Switch,
  FormControlLabel,
  Tooltip,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import DataGridImprovedCobList from "./DataGridImprovedCobList";
import MenuCreateBillComponent from "../components/menuCreateBillComponent";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function DataGridClientes() {
  let { getApi, postApi } = useAuth();
  const [listCobr, setListCobr] = React.useState([]);
  const [loadingList, setLoadingList] = React.useState(true);

  const [page, setPage] = useState(0);
  const [limitPage, setLimitPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const columns = [
    {
      field: "release_date",
      headerName: "Data",
      width: "180",
      renderCell: (params: GridRenderCellParams<Date>) => (
        <React.Fragment>
          {new Date(params.row.release_date).toLocaleDateString("pt-br") +
            " " +
            new Date(params.row.release_date).toLocaleTimeString("pt-br")}
        </React.Fragment>
      ),
    },
    {
      field: "id",
      headerName: "Transação",
      width: "300",
      renderCell: (params: GridRenderCellParams<Date>) => (
        <React.Fragment>
          <Link
            onClick={(e) =>
              getLinkExtrato(params.row.type.id, params.row.identifier)
            }
          >
            {params.row.type.id == 14
              ? ""
              : params.row.id
              ? params.row.id
              : null}
          </Link>
        </React.Fragment>
      ),
    },

    {
      field: "short_description",
      headerName: "Descrição",
      width: "500",
      renderCell: (params: GridRenderCellParams<Date>) => (
        <React.Fragment>{params.row.short_description}</React.Fragment>
      ),
    },
    {
      field: "amount",
      headerName: "Valor",
      width: "500",
      renderCell: (params: GridRenderCellParams<Date>) => (
        <React.Fragment>
          <Typography
            sx={{
              color: parseFloat(params.row.amount) < 0 ? "red" : "green",
            }}
          >
            {" "}
            {parseFloat(params.row.amount).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </Typography>
        </React.Fragment>
      ),
    },
  ];

  function getLinkExtrato(type_id, identifier) {
    if ([1, 2, 6, 7,20,21].includes(type_id)) {
      window.location.href = "/cobrancas/show/" + identifier;
      return;
    } else if ([8, 9, 10, 11, 12, 13].includes(type_id)) {
      alert("Não há dados para exibição");
      //window.location.href="/cobrancas/show/"+identifier;
      return;
    } else if ([3, 4, 5].includes(type_id)) {
      alert("Não há dados para exibição");
      //window.location.href="/cobrancas/show/"+identifier;
      return;
    } else if ([19].includes(type_id)) {
      window.location.href = "/app/pagamento-de-contas/" + identifier;
      return;
    } else {
      alert("Não há dados para exibição");
    }
  }

  const [statusList, setStatusList] = useState([]);

  const [query_filterDateStart, setQuery_filterDateStart] = useState(null);
  const [query_filterDateEnd, setQuery_filterDateEnd] = useState(null);
  const [noRowsMessage, setNoRowsMessage] = React.useState(
    "Nenhum resultado encontrado."
  );

  function LoadQueryParams(limit_arg, page_arg) {
    let invoiceTmp = {};

    invoiceTmp["limit"] = limit_arg;
    invoiceTmp["page"] = page_arg;
    invoiceTmp["minimal_data"] = true;

    invoiceTmp["queryString"] = {
      entries: {},
    };

    // Filtro de Data

    invoiceTmp["queryString"]["entries"]["filterdatevalue"] = {
      start: query_filterDateStart,
      end: query_filterDateEnd,
    };

    return invoiceTmp;
  }

  useEffect(() => {
    LoadMyClients();
  }, []);

  const LoadMyClients = async (page_arg = null, limit_arg = null) => {
    setListCobr([]);
    setLoadingList(true);
    let Arrayp = LoadQueryParams(
      limit_arg != null ? limit_arg : limitPage,
      page_arg != null
        ? page_arg == 0
          ? page_arg
          : page_arg
        : page == 0
        ? page
        : page
    );
    let response = await postApi("/extract/list", Arrayp);

    if (response.message) {
      if (response.message == "This action is unauthorized.") {
        setNoRowsMessage("Acesso não autorizado");
        setLoadingList(false);

        return;
      }
    }

    setPage(response.page);
    setLimitPage(response.limit);
    setTotalRecords(response.total);
    setListCobr(response.lista.map((x) => x.entry));
    setLoadingList(false);
  };

  const ExportReport = async (
    document_type,
    page_arg = null,
    limit_arg = null
  ) => {
    let Arrayp = LoadQueryParams(
      limit_arg != null ? limit_arg : limitPage,
      page_arg != null
        ? page_arg == 0
          ? page_arg
          : page_arg
        : page == 0
        ? page
        : page
    );
    let response = await postApi(
      "/report/account_entry/" + document_type,
      Arrayp
    );

    if (response) {
      alert(
        "Seu relatório está sendo processado.\nEstaremos enviando dentro de poucos segundos."
      );
    }
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <Typography variant="h6" gutterBottom>
          Pesquise pela data da transação
        </Typography>
        <CardContent pb={1}></CardContent>

        <Grid container spacing={6}>
          <Grid item md={2}>
            <TextField
              id="query"
              label={"Data Inicial"}
              variant="outlined"
              style={{ minWidth: window.isNativeApp ? "150px" : "100px" }}
              fullWidth
              type="date"
              focused={true}
              onChange={(e) => setQuery_filterDateStart(e.target.value)}
              value={query_filterDateStart}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              id="query"
              label={"Data Final"}
              variant="outlined"
              fullWidth
              style={{ minWidth: window.isNativeApp ? "150px" : "100px" }}
              type="date"
              focused={true}
              onChange={(e) => setQuery_filterDateEnd(e.target.value)}
              value={query_filterDateEnd}
            />
          </Grid>
        </Grid>
        {window.isNativeApp && <Box style={{ marginTop: 10 }}></Box>}
        <Button
          variant="contained"
          color="primary"
          mt={3}
          onClick={(e) => LoadMyClients(0)}
        >
          Pesquisar
        </Button>

        <Divider my={6} />
        <Paper>
          <div
            style={{ height: window.isNativeApp ? null : 600, width: "100%" }}
          >
            <DataGridImprovedCobList
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              rows={listCobr}
              columns={columns}
              pageSize={window.isNativeApp ? 10 : limitPage}
              rowCount={totalRecords}
              page={page}
              onPageSizeChange={(e) => {
                LoadMyClients(null, e);
              }}
              paginationMode={"server"}
              onPageChange={(e) => {
                LoadMyClients(e, null);
              }}
              loading={loadingList}
              noRowsMessage={noRowsMessage}
            />

            <Divider my={3}></Divider>
          </div>
        </Paper>
        <Grid container justifyContent="right">
          <Button
            onClick={() => ExportReport(1)}
            color={"success"}
            variant="outlined"
          >
            Exportar Excel
          </Button>
          <Button
            onClick={() => ExportReport(2)}
            color={"error"}
            variant="outlined"
          >
            Exportar PDF
          </Button>
        </Grid>
      </Card>
    </React.Fragment>
  );
}

function ExtratoComponent() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);

  const handleClose = (value) => {
    ShowModalFunc(null);
  };

  let { getApi, postApi, ShowModalFunc, showModal, isInitializedBS } =
    useAuth();

  return (
    <React.Fragment>
      <Helmet title="Cobranças" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
            Extrato Bancário
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/cobrancas">
              Extrato
            </Link>
            <Typography>Listagem</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <MenuCreateBillComponent />
        </Grid>
      </Grid>

      <Divider my={6} />

      <DataGridClientes />
    </React.Fragment>
  );
}

export default ExtratoComponent;
