import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Work from '@mui/icons-material/Work';
import {
  DollarSign,
} from "react-feather";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";

import {
  Avatar as MuiAvatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  LinearProgress as MuiLinearProgress,
  Link,
  Typography, 
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  TableContainer,
  TableBody,
  Table,
  TableCell,
  Paper as MuiPaper,
  TableRow,
  Button,
  Tabs,
  Tab,
  Popover,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";


import TblItensCreateCarneMobile from "../createsignature/TblItensCreateCarneMobile"
import TblItensCreateCarneDesktop from "../createsignature/TblItensCreateCarneDesktop"

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import EditSignature from "./edit";
import InvoicesSignaturesComponent from "./invoices/InvoicesSignaturesComponent";
import SignatureItensComponent from "./itens/SignatureItensComponent";
import SignaturePrintInvoices from "../modalInvoiceGroup/SignaturePrintInvoices";
import ChangeSituation from "./edit/changeSituation";

const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';


const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Divider = styled(MuiDivider)(spacing);


const Paper = styled(MuiPaper)(spacing);


function SignatureComponent({signatureM,reload}) {
  let { getApi,postApi,deleteApi,putApi } = useAuth();


  const [openPrintInvoices, setOpenPrintInvoices] = React.useState(false);

  const [signatureId, setSignatureId] = React.useState(signatureM.id);
  const [openEditable, setOpenEditable] = React.useState(false);
  const [tabSignature, setTabSignature] = React.useState('invoices');
  const [anchorNextInvoice, setAnchorNextInvoice] = React.useState(null);
  const [savingItens, setSavingItens] = React.useState(false);
  const [openChangeSituation, setOpenChangeSituation] = React.useState(false);

  useEffect(() => {
    if(signatureM){
      setItensCobranca(signatureM.itens);
    }
  }, [signatureM]);


  let [itensCobranca, setItensCobranca] = useState(signatureM.itens);

  const handleTabSignature = (event, newValue) => {
    setTabSignature(newValue);
  };

  const HandleItensCob= async()=>{
    setSavingItens(true)
    let submitPost={
      itens:itensCobranca
    };

    let response = await putApi("/invoice/signature/itens/"+signatureId,submitPost);
    
    setSavingItens(false)
  
    if(response.error){
      if(response.error.itens){
        alert(response.error.itens.toString());
      }else{
        alert(response.error.toString())
      }
    }else if(response.itens){
      alert(response.itens.toString());
    }else {
      reload(false);
    }
  }


  return (
    <React.Fragment>
      
      <Helmet title={'Assinatura'} />

      <Typography variant="h4" gutterBottom display="inline">
      Detalhes da Assinatura
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/app">
          Dashboard
        </Link>
        <Link component={NavLink} to="/assinaturas">
          Assinaturas
        </Link>
        <Typography>{'Assiantura #'+signatureM.id}</Typography>
      </Breadcrumbs>

      <Divider my={4} />
     
      <Grid  container justifyContent="center">
      
        <Grid sm={12} md={12}>
          <Typography variant="subtitle2" color="text.secondary" component="body">
          ID: {signatureM.id}
          {signatureM.status.sub.id>1 &&
            <React.Fragment>
            <br></br> Status: {signatureM.status.name} - {signatureM.status.sub.name}
            </React.Fragment>
          }
          {signatureM.status.sub.id==1 &&
            <React.Fragment>
            <br></br> Status: {signatureM.status.name}
            </React.Fragment>
          }
       


          {signatureM.status.status_notes &&
            <React.Fragment>
            <br></br> Obs: {signatureM.status.status_notes?signatureM.status.status_notes:null}
            </React.Fragment>
          }
          
          </Typography>
        </Grid>

        <Grid sm={12} xl={4} mt={3} sx={{borderRight: '4px solid #eaeaea', marginRight:2}}>
     
        <TableContainer component={Paper} >
           <Table  size="medium" >
             <TableBody>
            
                 <TableRow
                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >   
                 <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                 <strong>ID da Assinatura</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                {signatureM.id}
                </TableCell>
              </TableRow>

              <TableRow
                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >   
                 <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                 <strong>Cliente</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                <a href={"/meus-clientes/visualizar/"+signatureM.costumer.id}
                target="_blank" rel="noreferrer"
                >{signatureM.costumer.fullname} - {signatureM.costumer.tax_identifier}</a>
                </TableCell>
              </TableRow>
              <TableRow
                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >   
                 <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                 <strong>Referência do cliente</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                {signatureM.costumer.reference?signatureM.costumer.reference:"Não definido"}
                </TableCell>
              </TableRow>

              <TableRow
                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >   
                 <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                 <strong>Referência da Assinatura</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                {signatureM.reference?signatureM.reference:"Não definido"}
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{ fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Vencimento Inicial</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                {new Date(signatureM.first_due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Qtd de Parcelas</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                {signatureM.installments==-1?"Indeterminado":signatureM.installments+'x'}
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Ciclo das Parcelas</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                {signatureM.installments_cycle.name}
                </TableCell>
              </TableRow>



           </TableBody>
          </Table>
        </TableContainer>
        
        </Grid>

        <Grid sm={12} xl={4} mt={3} ml={window.isNativeApp?0:1} sx={{borderRight: '4px solid #eaeaea', marginRight:2}}>
       
        
        <TableContainer component={Paper} >
          
          <Table  size="medium" >
            <TableBody>
            
             
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Dia de vencimento</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                {signatureM.due_day>9?signatureM.due_day:"0"+String(signatureM.due_day)}
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Taxa de Setup</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                  {signatureM.setup_fee_invoice &&
                  signatureM.setup_fee_invoice.enabled==true &&
                  <React.Fragment>
                    Valor: {
                      parseFloat(signatureM.setup_fee_invoice.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                    }
                    <br></br>
                      Vencimento: {new Date(signatureM.setup_fee_invoice.due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}
                  </React.Fragment>
                  }
                  
                  {signatureM.setup_fee_invoice &&
                  signatureM.setup_fee_invoice.enabled==false &&
                  "Não aplicado"
                  }
                
                </TableCell>
              </TableRow>


              
              
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Juros e Multa</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                 {
                  !signatureM.fine &&
                  "Não definido"
                 }
                 {
                  signatureM.fine &&
                  <React.Fragment>
                    Multa: {signatureM.fine.interest_modality!="percent" && "R$"} {parseFloat(signatureM.fine.fine_value).toFixed(2)}
                    {signatureM.fine.interest_modality=="percent" && "%"}
                    <br></br>
                    Juros ao mês: {signatureM.fine.fine_modality!="percent" && "R$"} {parseFloat(signatureM.fine.interest_value).toFixed(2)}
                    {signatureM.fine.fine_modality=="percent" && "% ao mês"}
                    
                  </React.Fragment>
                 }
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Desconto</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                 {
                  !signatureM.discount &&
                  "Não definido"
                 }
                 {
                  signatureM.discount &&
                  <React.Fragment>
                    
                   
                    {
                      signatureM.discount.goal_days>0?" "+signatureM.discount.goal_days+" dias antes de vencer":"No vencimento"
                    }
                     {signatureM.discount.modality!="percent" &&
                     parseFloat(signatureM.discount.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                     }
                     <br></br>
                     Desconto: 
                     {signatureM.discount.modality=="percent" &&
                     parseFloat(signatureM.discount.value).toFixed(2)
                     }
                     {signatureM.discount.modality=="percent" && "%"} 
                    
                  </React.Fragment>
                 }
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                
                <Typography
                  aria-owns={Boolean(anchorNextInvoice) ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => setAnchorNextInvoice(e.currentTarget)}
                  onMouseLeave={e => setAnchorNextInvoice(null)}>
                   Próxima Fatura a ser gerada
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                  {signatureM.status.id<=1 &&
                    <React.Fragment>
                    <Typography
                  aria-owns={Boolean(anchorNextInvoice) ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => setAnchorNextInvoice(e.currentTarget)}
                  onMouseLeave={e => setAnchorNextInvoice(null)}>
                  
                <small>
                  Vencimento: {new Date(signatureM.next_due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}
                </small>
                <br></br>
                <small>
                  Data da geração: {new Date( new Date(signatureM.next_due_date)
                  .setDate(
                    new Date(signatureM.next_due_date).getDate()-20
                  )).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}) }
                </small>
                </Typography>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={Boolean(anchorNextInvoice)}
                    anchorEl={anchorNextInvoice}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={e=> setAnchorNextInvoice(null)}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1 }}>
                      Data de vencimento da próxima fatura que será gerada
                    </Typography>
                  </Popover>
                  </React.Fragment>
                }
                {signatureM.status.id>1 &&
                  
                  <Typography sx={{ p: 1 }}>
                     Não será gerada. <br></br>
                    </Typography>
                }
                </TableCell>
              </TableRow>

              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >   
                <TableCell component="th" scope="row" sx={{fontSize:13, fontFamily:FontFamily, textAlign:"center"}}>
                <strong>Valor Assinatura</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:13, fontFamily:FontFamily}}>
                {
                   parseFloat( itensCobranca.reduce((prev, curr) => (curr.qtd*curr.amount)+prev, 0) ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) 

                }
                </TableCell>
              </TableRow>
              <Link  href={signatureM.cover_link} target="_blank" >
                  <Button variant="outlined" size="small" color={'boot_secondary'}>  Ver Capa</Button>
              </Link>
              {
                ![2,3].includes(signatureM.installments_cycle.id) &&
                <Button sx={{marginLeft:2}}
                variant="outlined"
                size="small"
                color={'boot_primary'}  
                disabled={signatureM.status.id!=1}
                onClick={e=> setOpenPrintInvoices(true)}
              >
                Imprimir Carnê
              </Button>
              }
             
              
                
             

          </TableBody>
          </Table>
        </TableContainer>



        </Grid>


      </Grid>
      

      <Grid container justifyContent={"center"} mt={4}>
      <Button
        variant="contained"
        size="small"
        color={'boot_secondary'}  
        onClick={e=> setOpenEditable(true)}
      >
        Editar Assinatura
      </Button>

      <Button
        variant="contained"
        size="small"
        color={'boot_danger'}  
        onClick={e=> setOpenChangeSituation(true)}
      >
       Alterar situação
      </Button>

     
 
      </Grid>

      <Grid container justifyContent={"center"} mt={3}>
        <Tabs 
  scrollButtons
  allowScrollButtonsMobile
          value={tabSignature}
          onChange={handleTabSignature}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="invoices" label="Cobranças Geradas" />
          <Tab value="itens" label="Itens da assinatura" />
        </Tabs>
      </Grid>
      {/* Tabs */}
      <Grid container justifyContent={"center"}>
      <Grid sm={12} xl={10}>
      {
        signatureM.id &&
        tabSignature=='invoices' &&
        <InvoicesSignaturesComponent key={"bl"+signatureM.id+'si'+signatureM.installments_generated} signatureM={signatureM} />
      }

      {
        signatureM.id &&
        tabSignature=='itens' &&
        
        <React.Fragment>
           {window.isNativeApp?
                  <TblItensCreateCarneMobile setItensCobranca={setItensCobranca} itensCobranca={itensCobranca}/>
                  :
                  <TblItensCreateCarneDesktop key={itensCobranca} setItensCobranca={setItensCobranca} itensCobranca={itensCobranca}/>
                  }
                  
        <center>
        <Typography variant="subtitle2"  color="text.secondary" gutterBottom mt={2}>
           Está alteração irá ter efeito na próxima fatura a ser gerada.
      </Typography>

        <Button sx={{mt:2}}
          variant="contained"
          size="medium"
          color={'boot_primary'} 
          onClick={e=> HandleItensCob()}
          disabled={savingItens}
        >
        Atualizar itens da assinatura
        </Button>
        
        {savingItens && 
             <CircularProgress />
        }
        </center>
        </React.Fragment>
      }

     
      </Grid>
      </Grid>

      
      {openEditable &&
      signatureM &&
        <EditSignature 
          signatureM={signatureM} 
          open={openEditable}
          onClose={e=> {setOpenEditable(false);} }
          reload={reload} />
      }

      {openChangeSituation &&
      signatureM &&
        <ChangeSituation 
          signatureM={signatureM} 
          open={openChangeSituation}
          onClose={e=> {setOpenChangeSituation(false);} }
          reload={reload} />
      }

    {
      openPrintInvoices &&
        signatureM &&
          <SignaturePrintInvoices
          signatureM={signatureM} 
          open={openPrintInvoices}
          setOpen={setOpenPrintInvoices}
          onClose={e=> {setOpenPrintInvoices(false);} }
          reload={reload} 
          />
    }
     
    

    </React.Fragment>
  );
}

export default SignatureComponent;
