import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import EditClient from "../edit/index";
import EditClientPasswordPortal from "../../Portal-do-Cliente/clientes-associados/edit";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import AddNewClient from "../add/index";
import DataGridImprovedClientList from "./DataGridImprovedClientList";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function DataGridClientes({ query_type, setQueryType, query, setQuery }) {
  let { getApi, postApi, deleteApi } = useAuth();
  const [listClients, setListClients] = React.useState([]);
  const [loadingList, setLoadingList] = React.useState(true);

  const [page, setPage] = React.useState(0);
  const [limitPage, setLimitPage] = React.useState(5);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const [openEditClient, setOpenEditClient] = React.useState(false);
  const [selectedEditValue, setSelectedEditValue] = React.useState([]);

  const [selectedEditValuePortal, setSelectedEditValuePortal] = React.useState(
    []
  );
  const [openEditClientPortal, setOpenEditClientPortal] = React.useState(false);

  const handleClickOpenEditClient = (selectedEditValue) => {
    setOpenEditClient(true);
    setSelectedEditValue(selectedEditValue);
  };

  const handleCloseEditClient = (value) => {
    setOpenEditClient(false);
    setSelectedEditValue(value);
  };

  const handleClickOpenEditPortalClient = (selectedEditValuePortal) => {
    setOpenEditClientPortal(true);
    setSelectedEditValuePortal(selectedEditValuePortal);
  };

  const handleCloseEditPortalClient = (value) => {
    setOpenEditClientPortal(false);
    setSelectedEditValuePortal(value);
  };

  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  let columns;

  const navigate = useNavigate();
  if (window.isNativeApp) {
    columns = [
      { field: "id", headerName: "ID", width: 150, hide: true },
      {
        field: "fullname",
        headerName: "Nome",
        sortable: false,
        width: 150,
        headerAlign: "left",
        align: "left",
        renderCell: (params: GridRenderCellParams<Date>) => (
          <React.Fragment>
            <Link
              variant="text"
              tabIndex={params.hasFocus ? 0 : -1}
              onClick={(e) =>
                navigate("/meus-clientes/visualizar/" + params.row.id)
              }
            >
              {params.row.fullname}
            </Link>
          </React.Fragment>
        ),
      },
      {
        field: "tax_identifier",
        headerName: "CPF/CNPJf",
        type: "number",
        width: 150,
        sortable: false,
        headerAlign: "left",
        align: "left",
        renderCell: (params: GridRenderCellParams<Date>) => (
          <React.Fragment>{params.row.tax_identifier}</React.Fragment>
        ),
      },
      {
        field: "action",
        headerName: "Ação",
        sortable: false,
        width: 100,
        renderCell: (params: GridRenderCellParams<Date>) => (
          <React.Fragment>
            <Button
              variant="contained"
              size="small"
              color={"primaryboot"}
              style={{ marginLeft: 16 }}
              tabIndex={params.hasFocus ? 0 : -1}
              onClick={(e) =>
                navigate("/meus-clientes/visualizar/" + params.row.id)
              }
            >
              Visualizar
            </Button>
          </React.Fragment>
        ),
      },
    ];
  } else {
    columns = [
      { field: "id", headerName: "ID", width: 150, hide: true },
      {
        field: "reference",
        headerName: "Referência",
        width: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "fullname",
        headerName: "Nome Completo",
        width: 200,
        editable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Date>) => (
          <React.Fragment>
            <Link
              variant="text"
              tabIndex={params.hasFocus ? 0 : -1}
              href={"/meus-clientes/visualizar/" + params.row.id}
            >
              {params.row.fullname}
            </Link>
          </React.Fragment>
        ),
      },
      {
        field: "tax_identifier",
        headerName: "CPF/CNPJ",
        width: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "email",
        headerName: "E-mail",
        editable: false,
        sortable: false,
        width: 200,
        renderCell: (params: GridRenderCellParams<String>) => (
          <span>
            {params.row.email != "naotememail@cachebank.com.br" &&
            params.row.email
              ? params.row.email
              : "Não definido"}
          </span>
        ),
      },
      {
        field: "phone",
        headerName: "Telefone",
        sortable: false,
        width: 140,
      },
      {
        field: "action",
        headerName: "Ação",
        sortable: false,
        width: 400,
        renderCell: (params: GridRenderCellParams<Date>) => (
          <React.Fragment>
            <Button
              variant="contained"
              size="small"
              color={"primaryboot"}
              style={{ marginLeft: 16 }}
              tabIndex={params.hasFocus ? 0 : -1}
              onClick={(e) =>
                navigate("/meus-clientes/visualizar/" + params.row.id)
              }
            >
              Visualizar
            </Button>

            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ marginLeft: 16 }}
              tabIndex={params.hasFocus ? 0 : -1}
              onClick={(e) => handleClickOpenEditClient(params.row)}
            >
              Editar
            </Button>
            {[1].includes(params.row.status.id) && (
              <Button
                variant="contained"
                size="small"
                color="success"
                style={{ marginLeft: 16 }}
                tabIndex={params.hasFocus ? 0 : -1}
                onClick={(e) => handleClickOpenEditPortalClient(params.row)}
              >
                Alterar Senha
              </Button>
            )}

            <Button
              variant="contained"
              size="small"
              color="error"
              style={{ marginLeft: 16 }}
              onClick={(e) => deleteUser(params.row.id)}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              Excluir
            </Button>
          </React.Fragment>
        ),
      },
    ];
  }

  const [customFields, setCustomFields] = React.useState([]);
  const [customFieldSelected, setCustomFieldSelected] = React.useState(null);
  const [customFieldValueSelected, setCustomFieldValueSelected] =
    React.useState(null);

  useEffect(() => {
    loadCustomField();
    LoadMyClients();
  }, []);

  const LoadMyClients = async (page_arg = null, limit_arg = null) => {
    setListClients([]);
    setLoadingList(true);
    let response = await getApi("/costumer/list", {
      limit: limit_arg != null ? limit_arg : limitPage,
      page:
        page_arg != null
          ? page_arg == 0
            ? page_arg
            : page_arg
          : page == 0
          ? page
          : page,
      query_type: query_type,
      queryString: query,
    });

    if(response.message){
      if(response.message=='This action is unauthorized.'){
        alert("Você não possui permissão para isso");
        setLoadingList(false);
        return;
      }
    }

    setPage(response.page);
    setLimitPage(response.limit);
    setTotalRecords(response.total);
    setListClients(response.lista.map((x) => x.costumer));
    setLoadingList(false);
  };

  const loadCustomField = async () => {
    let response = await getApi("/custom_field", {});

    setCustomFields(response.lista.map((x) => x.custom_field));
  };

  const deleteUser = async (id) => {
    if (!window.confirm("Você realmente deseja fazer isso?")) {
      return;
    }

    setLoadingList(true);
    let response = await deleteApi("/costumer/" + id, {});
    if (response.success == true) {
      alert("Cliente excluido com sucesso");

      setLoadingList(false);
      LoadMyClients();
    }
    else if(response.message){
      if(response.message=='This action is unauthorized.'){
        alert("Você não possui permissão para isso");
      }
    }

    setLoadingList(false);
  };

  return (
    <Card mb={6}>
      <CardContent pb={1}></CardContent>
      <Typography variant="h6" gutterBottom>
        Procurar
      </Typography>
      <Grid container spacing={6}>
        <Grid
          item
          md={6}
          style={{ minWidth: window.isNativeApp ? "100%" : "100px" }}
        >
          {customFields.find(
            (x) => x.id == query_type.replace("custom-field-id-", "")
          ) &&
          customFields.find(
            (x) => x.id == query_type.replace("custom-field-id-", "")
          ).datatype.id == 9 ? (
            <Select
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            >
              {customFields
                .find((x) => x.id == query_type.replace("custom-field-id-", ""))
                .selectable_value.map((el) => (
                  <MenuItem value={el.id}>{el.value}</MenuItem>
                ))}
            </Select>
          ) : (
            <TextField
              id="query"
              placeholder={query_type == "fullname" && "Nome do cliente"}
              variant="outlined"
              fullWidth
              my={2}
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          )}
        </Grid>
        <Grid
          item
          md={4}
          style={{ minWidth: window.isNativeApp ? "100%" : "100px" }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Tipo de pesquisa
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={"Tipo de pesquisa"}
              value={query_type}
              onChange={(e) => setQueryType(e.target.value)}
            >
              <MenuItem value={"fullname"}>Nome do cliente</MenuItem>
              <MenuItem value={"tax_identifier"}>CPF/CNPJ</MenuItem>
              <MenuItem value={"email"}>E-mail</MenuItem>
              <MenuItem value={"phone"}>Telefone</MenuItem>
              <MenuItem value={"zipcode"}>Endereço - CEP</MenuItem>
              <MenuItem value={"street"}>Endereço - Logradouro</MenuItem>
              <MenuItem value={"district"}>Endereço - Bairro</MenuItem>
              <MenuItem value={"city"}>Endereço - Cidade</MenuItem>
              <MenuItem value={"state"}>Endereço - Estado</MenuItem>

              {customFields.map((value) => (
                <MenuItem value={"custom-field-id-" + value.id}>
                  Campo Personalizado - {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {window.isNativeApp && <Box style={{ marginTop: 10 }}></Box>}
      <Button
        variant="contained"
        color="primary"
        mt={3}
        onClick={(e) => LoadMyClients(0)}
      >
        Pesquisar
      </Button>

      <Divider my={6} />
      <Paper>
        <div style={{ height: window.isNativeApp ? null : 400, width: "100%" }}>
          <DataGridImprovedClientList
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            rows={listClients}
            columns={columns}
            pageSize={window.isNativeApp ? 25 : limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={(e) => {
              LoadMyClients(null, e);
            }}
            paginationMode={"server"}
            onPageChange={(e) => {
              LoadMyClients(e, null);
            }}
            loading={loadingList}
            handleClickOpenEditPortalClient={handleClickOpenEditPortalClient}
            handleClickOpenEditClient={handleClickOpenEditClient}
            deleteUser={deleteUser}
          />

          <EditClient
            selectedValue={selectedEditValue}
            open={openEditClient}
            onClose={handleCloseEditClient}
          />

          <EditClientPasswordPortal
            selectedValue={selectedEditValuePortal}
            open={openEditClientPortal}
            onClose={handleCloseEditPortalClient}
          />
        </div>
      </Paper>
    </Card>
  );
}

function DataGridPage() {
  let { getApi, postApi, deleteApi } = useAuth();

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const [query_type, setQueryType] = React.useState("fullname");
  const [query, setQuery] = React.useState(null);

  const ExportReport = async (document_type) => {
    let response = await postApi("/report/costumer/"+document_type, {
      query_type: query_type,
      queryString: query,
    });

    if (response) {
      alert(
        "Seu relatório está sendo processado.\nEstaremos enviando dentro de poucos segundos."
      );
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Meus Clientes" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
            Meus Clientes
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/meus-clientes">
              Meus Clientes
            </Link>
            <Typography>Listagem</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              <AddIcon />
              Cadastrar novo cliente
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <DataGridClientes
        setQueryType={setQueryType}
        query_type={query_type}
        setQuery={setQuery}
        query={query}
      />

      <Grid container justifyContent="right">
        <Button onClick={() => ExportReport(1)} color={"success"} variant="outlined">
          Exportar Excel
        </Button>
        <Button onClick={() => ExportReport(2)} color={"error"} variant="outlined">
          Exportar PDF
        </Button>
      </Grid>

      <AddNewClient open={open} onClose={handleClose} />
    </React.Fragment>
  );
}

export default DataGridPage;
