import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import useAuth from "../../../hooks/useAuth";
import OperatorsListComponent from "./lista";
import AddOperatorByBSAccount from "../add";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function OperatorsList() {

  let { getApi, postApi, ShowModalFunc, showModal, isInitializedBS } =
    useAuth();
    const [openAdd, setOpenAdd] = React.useState(false);

    const handleCloseAdd = (value) => {
      setOpenAdd(false);
    };

  return (
    <React.Fragment>
      <Helmet title="Meus Operadores" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
            Meus Operadores
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/operadores">
              Operadores
            </Link>
            <Typography>Listagem</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button variant="contained" color="primary" onClick={e=> setOpenAdd(true)}>
              <AddIcon />
              Cadastrar novo operador
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Typography variant="h6" gutterBottom>
        Gerencie quem tem acesso a sua conta
      </Typography>

      <OperatorsListComponent />


      <React.Fragment key={openAdd}>
        <AddOperatorByBSAccount
          open={openAdd}
          key={openAdd}
          onClose={handleCloseAdd}
        />

        </React.Fragment>
    </React.Fragment>
  );
}

export default OperatorsList;
