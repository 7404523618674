import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { Box } from "@mui/system";
import EditOperatorByBSAccount from "../edit";
import AddOperatorByBSAccount from "../add";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function CustomizedTables() {
  let { getApi, postApi, deleteApi } = useAuth();

  const [operators, setOperators] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [openEdit, setOpenEdit] = React.useState(false);


  const loadOperators = async () => {
    let response = await getApi("/operators", {});
    setLoading(true);
    if (response.message) {
      if (response.message == "This action is unauthorized.") {
        alert("Acesso não autorizado");
        setErrorMsg("Acesso não autorizado");
        setLoading(false);
        return;
      }
    } else {
      setOperators(response.lista.map((x) => x.operator));
      setLoading(false);
      setErrorMsg("");
    }
  };

  const deleteOperator = async (el) => {

    if(!window.confirm("Você realmente deseja excluir o operador\nNome :"+el.name+'\nE-mail: '+el.email+'\nCPF: '+el.tax_identifier)){
      return ;
    }
    let response = await deleteApi("/operators/"+el.id, {});
    setLoading(true);

    if (response.message) {
      if (response.message == "This action is unauthorized.") {
        alert("Acesso não autorizado");
        setErrorMsg("Acesso não autorizado");
        setLoading(false);
        return;
      }
    }

    if(response.success){
      setLoading(true);
      alert("Operador excluido com sucesso");
      loadOperators();
    }


  };


  React.useEffect(() => {
    loadOperators();
  }, []);

  const [selectedValue, setSelectedValue] = React.useState();
  const handleClickOpen = (e) => {
    setOpenEdit(true);
    setSelectedValue(e);
  };

  const handleCloseEdit = (value) => {
    setOpenEdit(false);
    setSelectedValue(value);
  };


  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Nome</StyledTableCell>
              <StyledTableCell align="left">CPF</StyledTableCell>
              <StyledTableCell align="left">E-mail</StyledTableCell>
              <StyledTableCell align="left">Telefone</StyledTableCell>
              <StyledTableCell align="left">Nivel</StyledTableCell>
              <StyledTableCell align="left">Situação</StyledTableCell>
              <StyledTableCell align="left">Ação</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operators.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="left">{row.name}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.tax_identifier}
                </StyledTableCell>
                <StyledTableCell align="left">{row.email}</StyledTableCell>
                <StyledTableCell align="left">{row.phone}</StyledTableCell>
                <StyledTableCell align="left">{row.level.name}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.level.status.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={
                       row.level.id=='1a'
                    }
                    onClick={e=> handleClickOpen(row)}
                  >
                    Editar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    size="small"
                    disabled={
                       row.level.id=='1a'
                    }
                    onClick={e=> deleteOperator(row)}
                  >
                    Excluir
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography color="#df4759" variant="h7"></Typography>
            {loading && (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
            {errorMsg}
          </Grid>
        </Grid>
      </TableContainer>

      {selectedValue && 
        selectedValue.id && 
        <React.Fragment key={selectedValue}>
        <EditOperatorByBSAccount
          operator={selectedValue}
          open={openEdit}
          key={selectedValue}
          onClose={handleCloseEdit}
        />

        </React.Fragment>
      }


    </React.Fragment>
  );
}
