import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  CardActions,
  TableHead,
  TableContainer,
  TableCell,
  Paper,
  TableRow,
  TableBody
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../estados-brasileiros'

import { useNavigate } from "react-router-dom";
import { Table } from "react-feather";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";


function AddNewClient(props) {
  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();

  const { onClose, invoice, open } = props;



const [loading, setLoading] = useState(false);


  useEffect(() => {
 
  },[invoice]);

  const handleClose = () => {
    onClose(invoice);
  };


  let [due_date, setDueDate] =  useState()

  const HandleSubmit = async()=>{
    if(!due_date)
    {
      alert("Você precisa informar o novo vencimento.");
      return ;
    }
    setLoading(true);
    let response = await postApi("/invoice/update/due_date/"+invoice.id, {
      due_date:due_date
    });
    
    if(response.message){
      if(response.message=='This action is unauthorized.'){
        alert("Acesso não autorizado");
        setLoading(false);
  
        return ;
      }
    }

    if(response.success){
      invoice.due_date=due_date;
      alert("O vencimento foi alterado com sucesso")
      handleClose(invoice)
    }else{
      if(response.error){
        alert(response.error)
      }else if(response.due_date){
        alert(response.due_date.toString())
      }else{
        alert("Um erro ocorreu, por favor, tente novamente.")
      }
    }


    setLoading(false);
  }
  

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"xs"} >
      <DialogTitle id="simple-dialog-title" ><center>Atualização de vencimento</center></DialogTitle>
       
          <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <CardContent>

              <Grid container spacing={6} justifyContent="center">
                <Grid item md={12} textAlign='center'>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Vencimento atual"
                    inputFormat="dd/MM/yyyy"
                    value={invoice.due_date}
                    disabled={true}
                    minDate={new Date()}
                    
                    renderInput={(params) => <TextField  fullWidth {...params} />}
                  />
                  
                </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12} textAlign='center'>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  
                    label="Novo vencimento"
                    inputFormat="dd/MM/yyyy"
                    onChange={setDueDate}
                    value={due_date?due_date:null}
                    fullWidth
                    minDate={new Date()}
                    maxDate={new Date().setFullYear(new Date().getFullYear() + 2)}
                    renderInput={(params) => <TextField fullWidth {...params} disabled={loading} />}
                  />
                  
                </LocalizationProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          </Grid>
          </Grid>
     
        

        <Grid container spacing={0} marginLeft={2} marginRight={2}>
         
          <Grid item xs={12} >
          {loading && 
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>}
          <Box textAlign='right' marginTop={7} marginBottom={5} marginRight={3}>
              <Button variant='contained' disabled={loading} color="secondary" onClick={handleClose}>
               Fechar
              </Button>
              <Button variant='contained' disabled={loading} onClick={HandleSubmit} color="boot_primary">
               Salvar
              </Button>
          </Box>
       
     
          </Grid>
       
              
        </Grid>

                




    </Dialog>
  );
}


export default AddNewClient;
