import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import TblItensCreateCarneMobile from "./TblItensCreateCarneMobile"
import TblItensCreateCarneDesktop from "./TblItensCreateCarneDesktop"
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {ptBR} from 'date-fns/locale'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  FormLabel,
  FormGroup
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";

import { useNavigate } from "react-router-dom";
import AddNewClient from "../../meus-clientes/add";
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

Date.prototype.subtractDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
}


function CreateSignature(props) {

  const navigate = useNavigate();


  

  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS,ShowModalFunc,businessAccount } = useAuth();
 
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
    ShowModalFunc(null)
  };
  
  let [saving, setSaving] = useState(false);
  let [errormsg, setErrorMsg] = useState('');
  
  let [listCostumer, setListCostumer] = useState([]);
  let [loadlistCostumer, setLoadingListCostumer] = useState(false);

  let [identifier, setIdentifier] = useState('');
  let [fullname, setFullname] = useState('');
  let [email, setEmail] = useState('');

  let [costumer_id, setCostumerId] = useState(null);
  let [phone, setPhone] = useState('');


  let [searchFullName, setSearchFullName] = useState('');

  let [reference, setReference] = useState('');

  let [createdCarne, setcreatedCarne] = useState(false);
  let [responseCarne, setresponseSignature] = useState({});


  let [due_day, setDueDay] =  useState(new Date().getDate());
  let [first_due_date, setFirstDueDate] =  useState(   dayjs(new Date()),
  );

  let [setup_due_date, setupDueDate] =  useState(null);


  let [installments, setInstallments] = useState(-1);
  let [applySetupPrice, setApplySetupPrice] = useState(false);
  let [setupPrice, setSetupPrice] = useState(0);

  let [cycletype, setCycleType] = useState(1);


  let [applyFees, setApplyFees] = useState(false);
  let [deadlineDays, setDeadlineDays] = useState(1);
  let [fine_modality, setFineModality] = useState("percent");
  let [interest_modality, setInterestModality] = useState("percent");
  let [fine_value, setFineValue] = useState(null);
  let [interest_value, setInterestValue] = useState(null);


  
  let [discount_modality, setDiscountModality] = useState("percent");
  let [discount_value, setDiscountValue] = useState(null);
  let [discount_goal_days, setDiscountGoalDays] = useState(0);

  let [allowBoleto, setAllowBoleto] = useState(false);
  let [allowPix, setAllowPix] = useState(false);
  let [allowCard, setAllowCard] = useState(false);
  
  let [openCadCostumer, setOpenCadCostumer] = useState(false);

  let [autocompleteVal, setAutocompleteVal] = useState(null);

  const handleCloseCadCostumer = (value) => {
    setOpenCadCostumer(false);
    console.log(value);
    setCostumerId(value.id)
    setFullname(value.fullname)
    setIdentifier(value.tax_identifier)
    setEmail(value.email)
    setPhone(value.phone)
    setPhone(value.phone)

    
    setAutocompleteVal({ 
      id: value.id,
      fullname: value.fullname,
      tax_identifier:value.tax_identifier
     })
  };

  const setValorDesconto= (val) =>{

    let discount_valueTMP

    discount_valueTMP = String(val).replace(/\D/g, "");
  
    discount_valueTMP=discount_valueTMP/100;
    setDiscountValue(discount_valueTMP)
  }

  const setValorSetup= (val) =>{
    val = String(val).replace(/\D/g, "");
  
    val=val/100;
    setSetupPrice(val)
  }

  const setTFineValue= (val) =>{

    let fine_value_valueTMP

    fine_value_valueTMP = String(val).replace(/\D/g, "");
  
    fine_value_valueTMP=fine_value_valueTMP/100;
    setFineValue(fine_value_valueTMP)
  }

  const setTInterestValue= (val) =>{

    let interest_value_valueTMP

    interest_value_valueTMP = String(val).replace(/\D/g, "");
  
    interest_value_valueTMP=interest_value_valueTMP/100;
    setInterestValue(interest_value_valueTMP)
  }

  let [applyDiscount, setApplyDiscount] = useState(false);
  

const ChooseClient= (e,value)=>{
  if(!value){
    setCostumerId('')
  setFullname('')
  setIdentifier('')
  setEmail('')
  setPhone('')
  setPhone('')
  return ;
  }
  setCostumerId(value.id)
  setFullname(value.fullname)
  setIdentifier(value.tax_identifier)
  setEmail(value.email)
  setPhone(value.phone)
  setPhone(value.phone)

  setAutocompleteVal({ 
    id: value.id,
    fullname: value.fullname,
    tax_identifier:value.tax_identifier
   })
}

const LoadMyClients = async (fullnameArgs) => {
  if(fullnameArgs){
    if(fullnameArgs==0){
      return ;
    }
    if(fullnameArgs.length<3){
      return ;
    }
  }else{
    return ;
  }

  
  setListCostumer([]);
  setLoadingListCostumer(true);
  let response = await getApi("/costumer/list",{
      limit:400,
      page:0,
      query_type:"fullname",
      queryString:fullnameArgs?fullnameArgs:searchFullName
      
  });


  
  setListCostumer(response.lista.map(x=> x.costumer));
  setLoadingListCostumer(false);

};

useEffect(() => {
  if(open==true){
    //LoadMyClients();
  }
},[open]);

let [itensCobranca, setItensCobranca] = useState([]);


let [editableDueDay, setEditableDueDay] = useState(true);

const changeFirstDueDate = (e) =>{
  setFirstDueDate(e);

  if(editableDueDay){
    setDueDay(new Date(e).getDate());
  }
}


const response_run=(response)=>{
  
  if(response.success){
    setresponseSignature(response.signature)
    window.location.href="/assinaturas/show/"+response.signature.id;
    setSaving(false);
    return false;
  
  }else{
    setSaving(false);
    if(response.costumer_id){
      setErrorMsg(response.costumer_id.toString())
    }else if(response.first_due_date){
      setErrorMsg(response.first_due_date.toString())
    }else if(response.due_day){
      setErrorMsg(response.due_day.toString())
    }
    else if(response.reference){
      setErrorMsg(response.reference.toString())
    }else if(response.charges_types){
      setErrorMsg(response.charges_types.toString())
    }
    
    
    else if(response.itens){
      setErrorMsg(response.itens.toString())
    }else if(response.discount){
      if(response.discount.modality){
        setErrorMsg(response.discount.modality.toString())
      }
      if(response.discount.value){
        setErrorMsg(response.discount.value.toString())
      }
      
    
    }
    else if(response.fine){
      if(response.fine.deadline){
        setErrorMsg(response.fine.deadline.toString())
      }
      if(response.fine.fine_modality){
        setErrorMsg(response.fine.fine_modality.toString())
      }
      if(response.fine.fine_value){
        setErrorMsg(response.fine.fine_value.toString())
      }
      if(response.fine.interest_modality){
        setErrorMsg(response.fine.interest_modality.toString())
      }
      else if(response.fine.installments){
        setErrorMsg(response.fine.installments.toString())
      }
    }
    else if(response["itens.0.description"]){
      setErrorMsg("A descrição do item precisa ser preenchida")
    }else if(response["itens.0.qtd"]){
      setErrorMsg("A quantidade do item precisa ser preenchida")
    }
    else if(response.error){
      setErrorMsg( response.error.toString())
    }else if(response.message){
      if(response.message=='This action is unauthorized.'){
        setErrorMsg("Você não possui permissão para isso");
      }
    }else{
      setErrorMsg( "Ocorreu um erro, tente novamente.")
    }
    setSaving(false)
  }
}



const checkValues= ()=>{
  let valorTotal=itensCobranca.reduce((prev, curr) => (curr.qtd*curr.amount)+prev, 0)
  let itemsEmpty=itensCobranca.reduce((prev, curr) => ( 
    (curr.qtd<1 || curr.description=='' ) && 1   )+prev, 0)

    
  if(valorTotal<7){
    alert("O valor minimo para emitir um boleto é de R$ 7,00");
  }else if(!costumer_id){
    alert("Você precisa especificar um cliente");
  }else if(applyFees && fine_value<=0 || fine_value>20){
    alert("A multa precisa ter de 0.01% até 20%");
  }else if(applyFees && interest_value<=0.01 || interest_value>20){
    alert("O juros ao mês precisa ter de 0.01% até 20%");
  }else if(applyFees && ( !interest_value || !interest_value)){
    alert("Os juros e multa devem ser preenchidos");
  }else if(!first_due_date){
    alert("O primeiro vencimento precisa ser definido.");
  }
  else if(!due_day){
    alert("O dia do vencimento precisa ser definido.");
  }
  else if(applyDiscount && discount_modality=='percent' && ( discount_value>95 || discount_value<=0.01)){
    alert("Você não pode colocar esta porcentagem de desconto");
  }else if(applyDiscount && discount_modality=='fixedvalue' && ( discount_value>=valorTotal || discount_value<=0.01)){
    alert("O desconto precisa ser menor que o valor total ou maior que R$ 0.01");
  }else if(applyDiscount && ( !discount_value)){
    alert("O valor do desconto deve ser preenchido");
  }else if(itemsEmpty>0){
    alert("Os itens do pedido contém valores não preenchidos ou inválidos.");
  }
  else{
    return true;
  }
}





const HandleSubmit = async () => {
  setErrorMsg(null)
  setSaving(true)
  if(!checkValues()){
    setSaving(false)
    return false;
  }

  let charges_types=[];
  if(allowBoleto==true){
    charges_types.push(1);
  }else if(allowPix==true){
    charges_types.push(2);
  }else if(allowCard==true){
    charges_types.push(4);
  }

  let submitPost={
    costumer_id: costumer_id,
    reference:reference,
    itens:itensCobranca,
    first_due_date:first_due_date.toISOString().substr(0,10),
    due_day:parseInt(due_day),
    installments:installments,
    cycletype:parseInt(cycletype),
    setup_fee_invoice:{
      enable: setup_due_date?true:false,
      due_date:setup_due_date,
      amount:setupPrice
    },
    charges_types:charges_types
  };

  if(applyFees==true){
    let fine_TMP={
      fine_modality:fine_modality,
      fine_value:parseFloat(fine_value),
      interest_modality:interest_modality,
      interest_value:parseFloat(interest_value),
      deadline_days:1,
    }
    submitPost.fine=fine_TMP;
  }

  if(applyDiscount==true){
    let discount_TMP={
      modality:discount_modality,
      value:parseFloat(discount_value),
      goal_days:discount_goal_days,
    }
    submitPost.discount=discount_TMP;
  }


  let response = await postApi("/invoice/signature/create",submitPost);

  response_run(response);
}


const copiarboleto=()=>{
  let copyText = document.getElementById("barcode_createCarne");
  copyText.select();
  copyText.setSelectionRange(0, 99999)
  document.execCommand("copy");
  alert("Código de barras copiado com sucesso!");
}


const windowSize = useRef([window.innerWidth, window.innerHeight]);
const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
const [options, setOptions] = React.useState([]);
const loading = open && options.length === 0;

React.useEffect(() => {
  if (!openAutocomplete) {
    setListCostumer([]);
  }
}, [openAutocomplete]);



React.useEffect(() => {
  let active = true;

  if (!loading) {
    return undefined;
  }

  (async () => {

    if (active) {
      LoadMyClients(searchFullName);
    }
  })();

  return () => {
    active = false;
  };
}, [loading]);



  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      style={{height:"94%", width:windowSize.current[0]}}
      maxWidth={createdCarne?"sm":window.isNativeApp?"lg":"md"}
    >
      <DialogTitle id="simple-dialog-title" ><center>{createdCarne?"Assinatura emitida com sucesso!":"Emissão de Assinatura"}</center></DialogTitle>
       
    {createdCarne==false &&
    
      <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <Grid container justifyContent="center" >
              <Grid item>
             
              </Grid>
            </Grid>

            <CardContent>

              <Grid container spacing={6}>
                <Grid item md={12} 
                style={{width:window.isNativeApp&&"100%"}}>
                <Autocomplete
                value={autocompleteVal}
                open={openAutocomplete}
                onOpen={() => {
                  setOpenAutocomplete(true);
                }}
                onClose={() => {
                  setOpenAutocomplete(false);
                }}
                  id="country-select-demo"
                  fullWidth
                  
                  noOptionsText={searchFullName.length>3?"Não encontrado":"Digite as 3 primeiras letras"}
                  
                  options={listCostumer}
                  loading={loadlistCostumer}
                  autoSelect
                  getOptionLabel={(option) => option.fullname+" - "+option.tax_identifier + " ("+option.id+")"}
                  onChange={ChooseClient}
                  onInputChange={ (e,native)=> {setAutocompleteVal(null);setSearchFullName(native); LoadMyClients(native) }}
                  renderOption={(props, option) => (
                    <Box key={option.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.fullname} - {option.tax_identifier}
                    </Box>
                  )}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={window.isNativeApp?"":"Digite o nome do cliente"}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(searchFullName.length<=3 && !costumer_id  ) ?<Typography variant="overline">{window.isNativeApp?"Digite o nome do seu cliente":"Digite as 3 primeiras letras"}</Typography> : 
                            ( loadlistCostumer ? <CircularProgress color="inherit" size={20} /> : null)
                            }
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              {loadlistCostumer &&
                <LinearProgress />}
                {!costumer_id  &&
                <Button
                onClick={e=> setOpenCadCostumer(true)}
                >Cadastrar novo cliente</Button>
                }
                </Grid>
              </Grid>
              
              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Endereço de e-mail *</label>
                  <TextField
                    id="fullname"
                    placeholder={"E-mail"}
                    variant="outlined"
                    value={email}
                    fullWidth
                    disabled={true}
                    onChange={e => setEmail(e.target.value)}
                    my={2}
                    type="email"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12} sx={{width:"100%"}}>
                <label for="my-input">Telefone *</label>
                <InputMask
                  mask={ ( String(String(phone).replace(/\D/g, "")).substring(2,3) >=5 )?"(99) 9 9999-9999":"(99) 9999-9999"}
                
                  maskChar="_"
                  placeholder="Telefone"
                  value={phone}
                  disabled={true}
                  fullWidth
                  my={2}
                  onChange={e => setPhone(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="phone"
                  type="text"
                  id="phone"
                  disabled={true}
                  autoComplete="phone"
                  placeholder="Telefone"
                />}
                </InputMask>
                </Grid>
              </Grid>
              
              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Referência da cobrança</label>
                  <TextField
                    id="reference"
                    placeholder={"Nº contrato, referência e etc.."}
                    variant="outlined"
                    value={reference}
                    onChange={e => setReference(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  {window.isNativeApp?
                  <TblItensCreateCarneMobile setItensCobranca={setItensCobranca} itensCobranca={itensCobranca}/>
                  :
                  <TblItensCreateCarneDesktop setItensCobranca={setItensCobranca} itensCobranca={itensCobranca}/>
                  }
                  
                </Grid>
              </Grid>
               
              <Grid container spacing={6}>
              <Grid item md={4}>
                  <FormControl sx={{ m: 1, width: '25ch' }}  >
                    <InputLabel id="demo-simple-select-label">Ciclo da assinatura</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Ciclo da assinatura"
                        onChange={e=> setCycleType(e.target.value)}
                        value={cycletype}
                      >
                        <MenuItem value={1}>Mensal</MenuItem>
                        {/* <MenuItem value={2}>Semanal</MenuItem>
                        <MenuItem value={3}>Quinzenal</MenuItem> */}
                        <MenuItem value={4}>Trimensal</MenuItem>
                        <MenuItem value={5}>Semestral</MenuItem>
                        <MenuItem value={6}>Anual</MenuItem>
                      </Select>
                  </FormControl>
                </Grid>

                <Grid item md={4}>
                  <FormControl sx={{ m: 1, width: '25ch' }}  >
                    <InputLabel id="demo-simple-select-label">Parcelas</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Parcelas da assinatura"
                        onChange={e=> setInstallments(e.target.value)}
                        value={installments}
                      >
                      <MenuItem value={-1}>Indeterminado</MenuItem>
                  {
                   [...Array(72)].map((e, i) => (
                      i>1 &&
                      <MenuItem key={i} value={i}>{i} parcelas</MenuItem>
                   ))
                   }
                      </Select>
                  </FormControl>
                </Grid>

            

                
               </Grid>

               <Grid container spacing={6} mb={2}>
               <Grid item md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Meios de Pagamento</FormLabel>
                    <FormGroup aria-label="position" row>
                    {businessAccount.allowed_charge_types &&
                      businessAccount.allowed_charge_types.includes(1) &&
                      <FormControlLabel
                        value="top"
                        control={<Checkbox />}
                        label="Boleto com Pix"
                        labelPlacement="top"
                        checked={allowBoleto}
                        onChange={e => setAllowBoleto(!allowBoleto)}
                      />

                    }
                      {businessAccount.allowed_charge_types &&
                        businessAccount.allowed_charge_types.includes(2) &&
                          <FormControlLabel
                            value="top"
                            control={<Checkbox />}
                            label="QrCode Pix"
                            labelPlacement="top"
                            checked={allowPix || allowBoleto}
                            disabled={allowBoleto}
                            onChange={e => setAllowPix(!allowPix)}
                          />
                       }
                      {businessAccount.allowed_charge_types &&
                        businessAccount.allowed_charge_types.includes(4) &&
                        <FormControlLabel
                        value="top"
                        control={<Checkbox />}
                        label="Cartão de Crédito"
                        labelPlacement="top"
                        checked={allowCard}
                        onChange={e => setAllowCard(!allowCard)}
                      />

                      }
                      

                  </FormGroup>

                </FormControl>
               </Grid>
              </Grid>
               <Grid container spacing={6}>
               <Grid item md={4}>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Primeiro Vencimento"
                    inputFormat="dd/MM/yyyy"
                    onChange={changeFirstDueDate}
                    value={first_due_date}
                    minDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  
                </LocalizationProvider>
                </Grid>

                <Grid item md={4}>
                
                <FormControl  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Dia de Vencimento do ciclo</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      label="Dia de vencimento"
                      type={'number'}
                      step={1}
                      max={30}
                      min={1}
                      onChange={e=> { setDueDay(e.target.value>30?30:e.target.value<1?1:e.target.value); setEditableDueDay(false) }}
                      value={parseInt(due_day)}
                    />
                  </FormControl>
                  
                </Grid>

                
               
               
              </Grid>

               <Grid container spacing={6}>

                <Grid item md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={applySetupPrice}
                        onChange={e=> setApplySetupPrice(!applySetupPrice)}
                        name="gilad"
                      />
                    }
                    label="Aplicar Taxa de adesão"
                  />
                </Grid>

               {applySetupPrice &&
                 <React.Fragment>
                  <Grid item md={4}>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Vencimento da taxa de adesão"
                    inputFormat="dd/MM/yyyy"
                    onChange={setupDueDate}
                    value={setup_due_date}
                    minDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  
                </LocalizationProvider>
                </Grid>

                <Grid item md={4}>
                
                 <FormControl  variant="outlined">
                   <TextField
                    label="Taxa da adesão"
                     placeholder={"Taxa da adesão"}
                     variant="outlined"
                     value={parseFloat(setupPrice).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                     onChange={x => setValorSetup(x.target.value)}
                     fullWidth
                     required
                   />
                   </FormControl>
                   
                 </Grid>
                 </React.Fragment>
                }
                


                </Grid>

              
              <Grid container spacing={6}>
                
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={applyFees}
                      onChange={e=> setApplyFees(!applyFees)}
                      name="gilad"
                    />
                  }
                  label="Aplicar multa após o vencimento"
                />
              </Grid>
            
            </Grid>

       
         
            
            {applyFees &&
              <Grid container spacing={6}>
                <Grid item md={3}>
                  <FormControl  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Multa</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      label="Multa"
                      type={'number'}
                      step={0.01}
                      max={20}
                      onChange={e=> setTFineValue(e.target.value)}
                      value={parseFloat(fine_value).toFixed(2)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControl  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Juros ao mês</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      label="Juros ao mês"
                      type={'number'}
                      step={0.01}
                      max={20}
                      onChange={e=> setTInterestValue(e.target.value)}
                      value={parseFloat(interest_value).toFixed(2)}
                    />
                  </FormControl>
                </Grid>
                
              </Grid>
            }


            
            <Grid container spacing={6}>
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Switch
                    checked={applyDiscount}
                    onChange={e=> setApplyDiscount(!applyDiscount)}
                      name="gilad"
                    />
                  }
                  label="Aplicar desconto antes de vencer"
                />
              </Grid>
            </Grid>
            {applyDiscount &&
              <Grid container spacing={6}>
                <Grid item md={4}>
                <FormControl sx={{ m: 1, width: '25ch' }}  >
                  <InputLabel id="demo-simple-select-label">Tipo de Desconto</InputLabel>
                    <Select fullWidth required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Tipo de Desconto"
                      onChange={e=> setDiscountModality(e.target.value)}
                      value={discount_modality}
                    >
                      <MenuItem value={'percent'}>Porcentagem</MenuItem>
                      <MenuItem value={'fixedvalue'}>Valor Fixo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">{discount_modality=="percent"?'%':'Reais'} de desconto</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                      <InputAdornment position="end">
                        {discount_modality=="percent"?'%':'reais'}
                        </InputAdornment>}
                      label="Juros ao mês"
                      type={'number'}
                      step={0.01}
                      max={20}
                      onChange={e=> setValorDesconto(e.target.value)}
                      value={discount_modality=="fixedvalue"?parseFloat(discount_value).toFixed(2).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}):parseFloat(discount_value).toFixed(2)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} >
                  <FormControl sx={{ m: 1, width: '25ch' }}  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Dias antes de vencer</InputLabel>
                  <Select required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status da assinatura"
                    fullWidth
                    value={discount_goal_days}
                    onChange={e=> setDiscountGoalDays(e.target.value)}
                    sx={{
                      textAlignLast:"center"
                    }}
                  >
                   <MenuItem value={0}>Até o vencimento</MenuItem>
                   <MenuItem value={1}>1 dia antes</MenuItem>
                   {
                   [...Array(60)].map((e, i) => (
                      i>1 &&
                      <MenuItem key={i} value={i}>{i} dias antes</MenuItem>
                   ))
                   }
                  </Select>

               
                  </FormControl>
             
              </Grid>
              </Grid>
            }
            

          
              <br></br>
              <br></br>
           
              <Grid container justifyContent="left" >
                <Grid item>
                    <Typography variant="subtitle1" >Valor Parcela: </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" > {parseFloat( itensCobranca.reduce((prev, curr) => (curr.qtd*curr.amount)+prev, 0) ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }</Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent="left" >
                  <Grid item>
                  <Typography variant="subtitle1" > {
                     (
                      (cycletype==1 && installments==-1) && "Fatura será emitido todos os meses" 
                    ) || 
                    (
                      (cycletype==2 && installments==-1) && "Fatura será emitida toda semana" 
                    ) || 
                    (
                      (cycletype==3 && installments==-1) && "Fatura será emitido em todas as quizenas" 
                    ) || 
                    (
                      (cycletype==4 && installments==-1) && "Fatura será emitido em todas os trimestres" 
                    ) || 
                    (
                      (cycletype==5 && installments==-1) && "Fatura será emitido em todas os semestres"  
                    ) || 
                    (
                      (cycletype==6 && installments==-1) && "Fatura será emitido em todas os anos" 
                    )  ||
                    (
                      installments>1 && 
                      "Qtd de Parcelas: "+installments
                    ) 
                    
                    }</Typography>
                  </Grid>
              </Grid>
             

              {applySetupPrice &&
               <React.Fragment>
              <Grid container justifyContent="left" >
                <Grid item>
                    <Typography variant="subtitle1" >Taxa de Adesão: </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" >{parseFloat(setupPrice ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }</Typography>
                </Grid>
              </Grid>
               </React.Fragment>
              }


              <Grid container justifyContent="right" >
                <Grid item>
                    <Button variant="contained" onClick={handleClose} disabled={saving} color="boot_secondary" mt={3} >
                      Cancelar
                    </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={HandleSubmit} disabled={saving} color="boot_primary" mt={3} >
                    Gerar Assinatura
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
              
              
            </CardContent>

        </Card>
        </Grid>
        </Grid>
                

    }
    {/* Inicio Exibição do Carnê criado */}
    {createdCarne==true &&
    
    <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <Grid container justifyContent="center" >
              <Grid item>
             
              </Grid>
            </Grid>

            <CardContent>

              <Grid container spacing={6}>
                <Grid item md={12} justifyContent={"center"} >
                  <br></br>
                  <Box textAlign='center' >
                    <Button
                       variant="contained"
                       size="medium"
                       color={'boot_secondary'}  
                       onClick={e => navigate("/assinaturas/show/"+responseCarne.id)}
                    >
                    Acessar Assinatura
                    </Button>

                  </Box>
                  
                  
                </Grid>
             
              </Grid>
            </CardContent>
        </Card>
        </Grid>
    </Grid>
    }
 <AddNewClient
      key={openCadCostumer}
      open={openCadCostumer}
      callbackCostumer={true}
      onClose={handleCloseCadCostumer}
    />
    </Dialog>
  );
}


export default CreateSignature;
