import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CarneEdit from "../edit/index"
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";

import DataGridImprovedCarneList from "./DataGridImprovedCarneList";
import CreateSignature from "../../signature/createsignature";
import MenuCreateBillComponent from "../../components/menuCreateBillComponent";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,postApi } = useAuth();
const [listCobr, setListCobr] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);





const [page, setPage] = useState(0);
const [limitPage, setLimitPage] = useState(5);
const [totalRecords, setTotalRecords] = useState(0);

const [openEditClient, setOpenEditClient] = useState(false);
const [selectedEditValue, setSelectedEditValue] = useState([]);


const [arrayResult, setArrayResult] = React.useState([]);


const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
};

const navigate = useNavigate();

const ColorButtonTransaction =(status)=>{
  let color="primary";
  if(status==0){
    color="boot_warning"
  }else if(status==1){
    color="boot_success"
  }else if(status==2){
    color="boot_info"
  }else if(status==3){
    color="boot_secondary"
  }else if(status==4){
    color="boot_dark"
  }else if(status==5){
    color="boot_danger"
  }else if(status==6){
    color="boot_dark"
  }else if(status==7){
    color="boot_success"
  }else if(status==8){
    color="boot_success"
  }else if(status==9){
    color="boot_success"
  }else if(status==10){
    color="boot_secondary"
  }

  return color;
}

const ExportReport = async (document_type, page_arg=null, limit_arg=null) => {
    
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi("/report/invoice_group/" + document_type, Arrayp);
  
  if(response){
    alert("Seu relatório está sendo processado.\nEstaremos enviando dentro de poucos segundos.")
  }

};


const columns = [
  { field: "id", headerName: "ID", width: 120,
  renderCell: (params: GridRenderCellParams<Date>)  => (
    <React.Fragment>
       <Link
       href="#"
          onClick={e=> handleClickOpenEditClient(params.row)} >
          {params.row.id?params.row.id:null}
        </Link>
    </React.Fragment>
  
      
  ) },
  {
    field: "created_at",
    headerName: "Criado em",
    sortable: false,
    width: 150,
    editable: false,
    renderCell: (params) => {
      let DateC=new Date(params.row.created_at);
      return <div className="rowitem"> {
        DateC.toLocaleDateString('pt-br')
        +" "+DateC.getHours()+":"+DateC.getMinutes()+":"+DateC.getSeconds()
        
        }</div>;
    }},
  {
    field: "fullname",
    headerName: "Nome Completo",
    sortable: false,
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.costumer.fullname}</div>;
  }
  },
  {
    field: "tax_identifier",
    headerName: "CPF/CNPJ",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.costumer.tax_identifier}</div>;
    }
  },
  {
    field: "reference",
    headerName: "Referência",
    sortable: false,
    width: 120,
  },
  {
    field: "due_date",
    headerName: "1º Vencimento",
    sortable: false,
    width: 140,
    renderCell: (params) => {
      let DateC=new Date(params.row.due_date);
      return <div className="rowitem"> {
        DateC.toLocaleDateString('pt-br')
        }</div>;
    }
  },
  {
    field: "amount",
    headerName: "V. Parcela",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return <div className="rowitem">{parseFloat(params.row.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>;
    }
  },
  {
    field: "Parcelastotais",
    headerName: "Parcelas",
    sortable: false,
    width: 90,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments}</div>;
    }
  },
   {
    field: "ParcelasPendente",
    headerName: "Pendentes",
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments_pending}</div>;
    }
  },
  {
    field: "ParcelasAtrasadas",
    headerName: "Atrasadas",
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments_overdue}</div>;
    }
  },
  {
    field: "ParcelasExpiradas",
    headerName: "Expiradas",
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.installments_expired}</div>;
    }
  },
  {
    field: "action",
    headerName: "Ação",
    sortable: false,
    width: 300,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          color={params.row.status?ColorButtonTransaction(params.row.status["id"]):"primary"}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e=> handleClickOpenEditClient(params.row)}>
          Visualizar
        </Button>
      </React.Fragment>
    
        
    ),
  },
];



const [statusList, setStatusList] = useState([]);

const [search_Status, setSearchStatus] = useState(-1);
const [searchText, setSearchText] = useState(null);
const [query_type, setQueryType] = useState("fullname");
const [query_filterDateType, setQuery_filterDateType] = useState(1);

const [query_filterDateStart, setQuery_filterDateStart] = useState(null);
const [query_filterDateEnd, setQuery_filterDateEnd] = useState(null);


function LoadQueryParams(limit_arg,page_arg){

  let invoiceTmp={};
  
  invoiceTmp["limit"]=limit_arg;
  invoiceTmp["page"]=page_arg;
  invoiceTmp["minimal_data"]=true;

  invoiceTmp["queryString"]={
    "invoiceGroup":{ },
    "status":search_Status
  };


  // Inclusão de Arquivados
  invoiceTmp["queryString"]["invoiceGroup"]["archived"]=includeArchivedInvoice;


   // Filtro de Data
   invoiceTmp["queryString"]["invoiceGroup"]["filterdate"]=query_filterDateType;
   invoiceTmp["queryString"]["invoiceGroup"]["filterdate"]=query_filterDateType;
    invoiceTmp["queryString"]["invoiceGroup"]["filterdatevalue"]={
     "start":query_filterDateStart?new Date(query_filterDateStart).toLocaleString("en", {timeZone: "America/Sao_Paulo"}):null,
     "end": query_filterDateEnd?new Date(query_filterDateEnd).toLocaleString("en", {timeZone: "America/Sao_Paulo"}):null
   };

   // Filtro de usuario
   if(["fullname", "tax_identifier", "email", "phone"].includes(query_type)){
     
    invoiceTmp["queryString"]["costumer"]={};
     if(query_type=="fullname"){
       invoiceTmp["queryString"]["costumer"]["fullname"]=searchText;
     }else if(query_type=="tax_identifier"){
       invoiceTmp["queryString"]["costumer"]["tax_identifier"]=searchText;
     } else if(query_type=="email"){
       invoiceTmp["queryString"]["costumer"]["email"]=searchText;
     }else if(query_type=="phone"){
       invoiceTmp["queryString"]["costumer"]["phone"]=searchText;
     }
   }


   // Invoice Filter
   if(["id", "reference"].includes(query_type)){
     if(query_type=="id"){
       invoiceTmp["queryString"]["invoiceGroup"]["id"]=searchText;
     }else if(query_type=="reference"){
       invoiceTmp["queryString"]["invoiceGroup"]["reference"]=searchText;
     }
   }


  return invoiceTmp;

}

useEffect(() => {

  LoadStatusList();
  LoadMyClients();
},[]);

const LoadStatusList = async (page_arg=null, limit_arg=null) => {
    
  let response = await getApi("/invoice/status/list",{ });
  setStatusList(response)
};


const [noRowsMessage, setNoRowsMessage] = React.useState('Nenhum resultado encontrado.');

const LoadMyClients = async (page_arg=null, limit_arg=10) => {
    

  setListCobr([]);
  setLoadingList(true);
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi("/invoice/carne/list", Arrayp);
  
  if(response.message){
    if(response.message=='This action is unauthorized.'){
      setNoRowsMessage("Acesso não autorizado");
      setLoadingList(false);

      return ;
    }
  }

  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListCobr(response.lista.map(x=> x.carne));
  setLoadingList(false);

};
const [includeArchivedInvoice, setIncludeArchiveddInvoice] = useState(false);


  return (
    <Card mb={6}>
      <CardContent pb={1}>
  
       
      </CardContent>
      <Typography variant="h6" gutterBottom>
          Procurar
        </Typography>
      <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="query"
                    placeholder={"Campo de busca"}
                    variant="outlined"
                    fullWidth
                    my={2}
                    type="text"
                    value={searchText}
                    onChange={e=> setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item md={4}>
                  
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo de pesquisa</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de pesquisa"}
                        value={query_type}
                        onChange={e => setQueryType(e.target.value)}>
                          <MenuItem value={"fullname"}>Nome do cliente</MenuItem>
                          <MenuItem value={"tax_identifier"}>CPF/CNPJ</MenuItem>
                          <MenuItem value={"id"}>Código do Carnê</MenuItem>
                          <MenuItem value={"reference"}>Código de Referência</MenuItem>
                          <MenuItem value={"email"}>E-mail</MenuItem>
                          <MenuItem value={"phone"}>Telefone</MenuItem>
                        
                      </Select>
                    </FormControl>
                   
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Status do Carnê"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status do pagamento Pagamento"
                        value={search_Status}
                        onChange={e=> setSearchStatus(e.target.value)}
                      >
                      <MenuItem value={-1}>Todos os Status </MenuItem>
                      <MenuItem value={1}>Pagamentos completados</MenuItem>
                      <MenuItem value={2}>Pagamentos pendentes</MenuItem>
                      <MenuItem value={3}>Pagamentos vencidos</MenuItem>
                      <MenuItem value={4}>Pagamentos Expirados</MenuItem>
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Filtrar por data"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Filtrar por data"
                        value={query_filterDateType}
                        onChange={e=> setQuery_filterDateType(e.target.value)}
                      >
                      <MenuItem value={1}>Emissão do carnê </MenuItem>
                      <MenuItem value={2}>Aprovação dos boletos </MenuItem>
                      <MenuItem value={3}>Vencimento dos boletos </MenuItem>
                      <MenuItem value={4}>1º Vencimento do carnê</MenuItem>
                      <MenuItem value={5}>Último vencimento do carnê</MenuItem>
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    id="query"
                    label={"Data Inicial"}
                    variant="outlined"
                    fullWidth
                    type="date"
                    focused={true}
                    onChange={e=> setQuery_filterDateStart(e.target.value)}
                    value={query_filterDateStart}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    id="query"
                    label={"Data Final"}
                    variant="outlined"
                    fullWidth
                    type="date"
                    focused={true}
                    onChange={e=> setQuery_filterDateEnd(e.target.value)}
                    value={query_filterDateEnd}
                  />
                </Grid>
                <Grid item md={3}>
                <Tooltip title="Inclui na listagem todos os carnês, incluindo os arquivados.">
                  <FormControlLabel
                      value={true}
                      checked={includeArchivedInvoice}
                      onClick={e=> setIncludeArchiveddInvoice(!includeArchivedInvoice)}
                      control={<Switch color="primary" />}
                      label="Incluir carnês arquivados"
                      labelPlacement="Incluir carnês arquivados"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Button variant="contained"  color="primary" mt={3} onClick={e => LoadMyClients(0)}>
                Pesquisar
              </Button>
              

              <Divider my={6} />
      <Paper>
      <div style={{ height: (window.isNativeApp?null:600), width: "100%" }}>
     

        
        <DataGridImprovedCarneList
            rowsPerPageOptions={[5, 10, 25, 50,100]}
            rows={listCobr}
            columns={columns}
            pageSize={window.isNativeApp?25:limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyClients(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyClients(e, null)  } }
            loading={loadingList}
            handleClickOpenEditClient={handleClickOpenEditClient}
            noRowsMessage={noRowsMessage}
            
          />
          
          {selectedEditValue.id &&
            <CarneEdit
              selectedValue={selectedEditValue}
              open={openEditClient}
              key={selectedEditValue.id}
              onClose={handleCloseEditClient}
            />
          }
    
        </div>
      </Paper>
      <Grid container justifyContent="right" >
        <Button onClick={()=> ExportReport(1)}  color={"success"} variant="outlined">Exportar Excel</Button>
        <Button onClick={()=> ExportReport(2)}  color={"error"} variant="outlined">Exportar PDF</Button>
      </Grid>
    </Card>
  );
}

function DataGridPage() {
  const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);

const handleClose = (value) => {
  ShowModalFunc(null)
};

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();





  return (
    <React.Fragment>
      <Helmet title="Lista de Carnes" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h4" gutterBottom display="inline">
        Meus Carnês
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/cobrancas">
          Carnês
        </Link>
        <Typography>Listagem</Typography>
      </Breadcrumbs>
      </Grid>
      <Grid item>
        <MenuCreateBillComponent />

        </Grid>
      
        </Grid>


      <Divider my={6} />
  
      <DataGridClientes />
   
       
    </React.Fragment>
  );
}

export default DataGridPage;
