import React from "react";
import { Box, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";



const LevelShowBox = ({resources}) => {
    const groupedCategories = resources.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          acc[item.category_name] = [];
        }
        acc[item.category_name].push(item);
        return acc;
      }, {});

  return (
    <Box sx={{ flexGrow: 1, p: 0.5 }}>
      <Grid container spacing={0.25}>
        {Object.entries(groupedCategories).map(([category, items]) => (
          <Grid item xs={12} key={category}>
            <Typography variant="body2" gutterBottom sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
              {category}
            </Typography>
            <Grid container spacing={0} sx={{ mb: 0 }}> {/* Reduzido o spacing para 0 */}
              {items.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.general_resources_id}>
                  <List dense sx={{ py: 0 }}> {/* Removido o padding vertical */}
                    <ListItem sx={{ py: 0 }}> {/* Removido o padding vertical */}
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        {item.enabled ? (
                          <CheckCircleIcon color="success" fontSize="small" />
                        ) : (
                          <CancelIcon color="error" fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "body2",
                          sx: { fontSize: "0.75rem" }
                        }}
                        primary={item.name}
                      />
                    </ListItem>
                  </List>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LevelShowBox;