import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import InputMask from "react-input-mask";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import { estadosBrasileiros } from "../../../estados-brasileiros";

import { useNavigate } from "react-router-dom";
import AccessListComp from "../LevelShowBox";
import LevelShowBox from "../LevelShowBox";

function AddOperatorByBSAccount(props) {
  let { getApi, postApi, user, putApi } = useAuth();

  const { onClose, open } = props;

  const handleClose = (e = null) => {
    onClose(e);
  };

  let [saving, setSaving] = useState(false);
  let [errormsg, setErrorMsg] = useState("");

  let [phone, setPhone] = useState("");
  let [levelId, setLevelId] = useState("1b");
  let [statusId, setStatusId] = useState(1);
  let [email, setEmail] = useState("");
  let [name, setName] = useState("");
  let [taxIdentifier, setTaxIdentifier] = useState("");

  let [levelList, setLevelList] = useState([]);

  const loadLevelList = async (event) => {
    let response = await getApi("/operators/levels", {});
    setLevelList(response.map((x) => x.level));
  };

  useEffect(() => {
    loadLevelList();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaving(true);
    setErrorMsg("");

    let response = await postApi("/operators", {
      level_id: levelId,
      email:email,
      status_id:statusId,
      tax_identifier:String(taxIdentifier).replace(/\D/g, ""),
      phone:String(phone).replace(/\D/g, ""),
      fullname:name,

    });

    response_run(response);
  };

  const response_run = (response) => {
    if (response.message) {
      if (response.message == "This action is unauthorized.") {
        alert("Acesso não autorizado");
        setErrorMsg("Acesso não autorizado");
        handleClose();
        setSaving(false);
        return;
      }
    }

    if (response.operator) {
      if (response.operator.id){
        alert("Operador adicionado com sucesso.");
        alert("Para que o novo operador tenha acesso. \nPeça-o para realizar a redefinição de senha usando o e-mail cadastrado.")
      }
        setErrorMsg(null);
        handleClose();
        window.location.reload(true);
    } else {
      if (response.error) {
        alert(response.error.toString());
        setErrorMsg(response.error.toString());
      }else if (response.level_id) {
        alert(response.level_id.toString());
        setErrorMsg(response.level_id.toString());
      }else if (response.status_id) {
        alert(response.status_id.toString());
        setErrorMsg(response.status_id.toString());
      }else if (response.phone) {
        alert(response.phone.toString());
        setErrorMsg(response.phone.toString());
      }else if (response.tax_identifier) {
        alert(response.tax_identifier.toString());
        setErrorMsg(response.tax_identifier.toString());
      }else if (response.fullname) {
        alert(response.fullname.toString());
        setErrorMsg(response.fullname.toString());
      }else if (response.email) {
        alert(response.email.toString());
        setErrorMsg(response.email.toString());
      }
      setSaving(false);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="simple-dialog-title">
        <center>Cadastrar Operador</center>
      </DialogTitle>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Card mb={1}>
            <Grid container justifyContent="center">
              <Grid item></Grid>
            </Grid>

            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <label for="my-input">Nome</label>
                  <TextField
                    id="fullname"
                    variant="outlined"
                    value={name}
                    placeholder="Nome do Operador"
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <label for="my-input">CPF/CNPJ *</label>
                   <InputMask
                               mask="999.999.999-99"
                               value={taxIdentifier}
                               disabled={false}
                               maskChar="_"
                               label="Seu CPF"
                               onChange={e=> setTaxIdentifier(e.target.value)}
                             >
                                {() => 
                                 <TextField   required
                                   fullWidth
                                   name="cpf"
                                   placeholder="Informe CPF do operador"
                                   type="text"
                                   id="cpf"
                                   autoComplete="cpf"
                                 />}
                             </InputMask>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <React.Fragment>
                    <label for="my-input">Endereço de e-mail *</label>
                    <TextField
                      id="fullname"
                      placeholder={"E-mail"}
                      variant="outlined"
                      value={email}
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                      my={2}
                      type="email"
                    />
                    <small>Para ter acesso, o operador precisa redefinir a senha usando este e-mail.</small>
                  </React.Fragment>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <label for="my-input">Telefone *</label>

                  <InputMask
                                mask={String(String(phone).replace(/\D/g, "")).substring(2,3)!=3?"(99) 9 9999-9999":"(99) 9999-9999"}
                                value={phone}
                                disabled={false}
                                maskChar="_"
                                label="Seu telefone"
                                onChange={e=> setPhone(e.target.value)} 
                              >
                                 {() => 
                                  <TextField   required
                                    fullWidth
                                    name="phone"
                                    placeholder="Telefone do operador"
                                    type="text"
                                    id="phone"
                                    autoComplete="phone"
                                   
                                  />}
                              </InputMask>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <label for="my-input">Status</label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {statusId ? "" : "Status *"}
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={statusId}
                      onChange={(e) => setStatusId(e.target.value)}
                    >
                      <MenuItem key={1} value={1}>
                        Ativado
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        Bloqueado
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <label for="my-input">Nivel de Acesso</label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {levelId ? "" : "Nivel *"}
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={levelId}
                      onChange={(e) => setLevelId(e.target.value)}
                    >
                      {levelList
                        .sort((a, b) => {
                          const aEndsWithB = a.id.endsWith("b"); // Verifica se o ID de 'a' termina com 'b'
                          const bEndsWithB = b.id.endsWith("b"); // Verifica se o ID de 'b' termina com 'b'

                          if (aEndsWithB && !bEndsWithB) {
                            return -1; // 'a' vem antes de 'b'
                          } else if (!aEndsWithB && bEndsWithB) {
                            return 1; // 'b' vem antes de 'a'
                          } else {
                            return a.id.localeCompare(b.id); // Mantém a ordem original para os demais
                          }
                        })
                        .map(
                          (el) =>
                            el.id !== "1a" && (
                              <MenuItem key={el.id} value={el.id}>
                                {el.name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                {levelId && levelList.length > 0 && (
                  <LevelShowBox
                    resources={levelList.find((x) => x.id == levelId).resources}
                  />
                )}
              </Grid>
              

              <Grid container justifyContent="center" mt={4}>
                <Grid item>
                  <Typography color="#df4759" variant="h7">
                    {errormsg}
                  </Typography>
                  {saving && (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                sx={{ marginTop: 5 }}
              >
                <Button
                  variant="contained"
                  onClick={handleClose}
                  disabled={saving}
                  color="boot_secondary"
                >
                  Fechar
                </Button>
                <Button
                  variant="contained"
                  disabled={saving}
                  color="primary"
                  mt={3}
                  sx={{ marginLeft: 3 }}
                  onClick={handleSubmit}
                >
                  Salvar informações
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default AddOperatorByBSAccount;
