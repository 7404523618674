import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import useAuth from "../../../../hooks/useAuth";
import Link from '@mui/material/Link';
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from 'react';
import UpdateDueDate from "../../../Cobrancas/update-due"


export default function BasicTable({blockAction,setBlockAction,invoices,noRowsMessage}) {
    const navigate = useNavigate();

let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();
  
const [openUpdateDue, setopenUpdateDue] = useState(false);
const [selectedValue, setSelectedValue] = useState(false);

const handleCloseInvoiceUpdateDue = (value) => {
  setopenUpdateDue(false);
  invoices.find(x=> x.id=value.id).due_date=value.due_date;
};

const HandleOpenUpdateDueDate = (value) => {
    setSelectedValue(value);
    setopenUpdateDue(true)
  };


    const ChangeStatus = async (status,invoice) => {
        if (!window.confirm("Você realmente deseja fazer isso?")) {
          return ;
        }
        setBlockAction(true);
        let response = await postApi("/invoice/marcar/"+status+"/"+invoice.id,{});
        if(response.error){
          alert(response.error.toString());
        }else if(response.message){
          if(response.message=='This action is unauthorized.'){
            alert("Você não tem autorização");
            setBlockAction(false)
    
            return ;
          }
        }else{
          let cob=invoice;
          if(status=="pago"){
            invoice["status"]["id"]=7;
            invoice["status"]["name"]="Marcado como Pago";
            invoice["status"]["extended_name"]="Marcado como Pago";
         
          }else  if(status=="cancelado"){
            invoice["status"]["id"]=3;
            invoice["status"]["name"]="Cancelada";
            invoice["status"]["extended_name"]="Transação Cancelada";
           
    
          }
        }
        setBlockAction(false)
      };

      


      const sentNotificationEmail=async (invoice)=>{
        if (!window.confirm("Você deseja enviar um lembrete via e-mail?")) {
          return ;
        }
      
        setBlockAction(true);
        let response = await postApi("/notification/email/invoices/pending",{
          invoices:[
            invoice.id
          ]
        });
        if(response.success){
          alert("Lembrete via e-mail enviado com sucesso.");
        }
        
        setBlockAction(false);
      }
      
      
      const sentNotificationSMS=async (invoice)=>{
        if (!window.confirm("Você deseja enviar um lembrete via sms? Taxa de envio pode ser aplicada.")) {
          return ;
        }
      
        setBlockAction(true);
        let response = await postApi("/notification/sms/invoices/pending",{
          invoices:[
            invoice.id
          ]
        });
        if(response.success){
          alert("Lembrete via SMS enviado com sucesso.");
        }
        
        setBlockAction(false);
      }

      

  return (
    <React.Fragment>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="center">Valor</TableCell>
            <TableCell align="center">Vencimento</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Notificação</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
      
          {invoices.map((invoice) => (
            <TableRow
              key={invoice.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                 <Link onClick={e => navigate("/cobrancas/show/"+invoice.id)}>
                    
                        {invoice.id} 
                </Link>
              </TableCell>
              <TableCell align="center">{parseFloat(invoice.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>
              <TableCell align="center">{new Date(invoice.due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>
              <TableCell align="center">{invoice.status["name"]}</TableCell>
              <TableCell align="center">
                <Button variant='outlined' color="boot_secondary" size={"small"} disabled={(!blockAction && ![0].includes(invoice.status["id"])) || blockAction} onClick={e=> sentNotificationEmail(invoice)}>
                   E-mail
                </Button>
                
                <Button variant='outlined' color="boot_secondary" onClick={e=> sentNotificationSMS(invoice)} size={"small"} disabled={(!blockAction && ![0].includes(invoice.status["id"])) || blockAction} >
                    SMS
                </Button>
              </TableCell>
              <TableCell align="center">
                <Button variant='outlined' color="boot_primary" size={"small"} disabled={(!blockAction && ![0,3,10].includes(invoice.status["id"])) || blockAction} onClick={e=> ChangeStatus("pago", invoice)}>
                    Marcar como pago
                </Button>
                
                <Button variant='outlined' color="secondary" onClick={e=> HandleOpenUpdateDueDate(invoice)} size={"small"} disabled={(!blockAction && invoice.status["id"]!=0 ) || blockAction  } >
                    Atualizar
                </Button>
                <Button variant='outlined' color="boot_danger" size={"small"} disabled={(!blockAction && ![0,7,10].includes(invoice.status["id"]))
            || blockAction}   onClick={e=> ChangeStatus("cancelado", invoice)}>
                    Cancelar
                </Button>
                {invoice.link &&
                    <Link
                      target="_blank"
                      href={invoice.link}>
                      <Button
                          variant="contained"
                          size="small"
                          color={"boot_warning"}>
                        Abrir
                      </Button>
                    </Link>
                  
                  }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    <center>{noRowsMessage}</center>
    </TableContainer>

<UpdateDueDate
invoice={selectedValue}
open={openUpdateDue}
onClose={handleCloseInvoiceUpdateDue}
/>
</React.Fragment>
  );
}